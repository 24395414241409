<template>
  <div v-if="$vuetify.breakpoint.smAndUp" class="toggle-action-wrapper" :id="id">
    <v-tooltip top content-class="v-tooltip--top">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="toggle-action"
          icon
          v-bind="attrs"
          v-on="on"
          @click="$emit('toggle')"
        >
          <v-icon
          v-if="!trigger"
          color="#919EAB"
          style="position:absolute;left:-24px;width:0"
        >visibivisibility_offlity</v-icon>
        <v-icon v-else color="#919EAB">visibility</v-icon>
        </v-btn>
      </template>
      <span>{{ trigger ? 'Hide action' : 'Show action' }}</span>
    </v-tooltip>
    <template v-if="trigger">
      <hr v-if="showLines" :style="{ top: topHorizontalLine.y, left: topHorizontalLine.x, width: topHorizontalLine.w }" class="horizontal-line horizontal-line-top">
      <hr v-if="showLines" :style="{ top: verticalLine.y, left: verticalLine.x, height: verticalLine.w }" class="vertical-line">
      <hr v-if="showLines" :style="{ top: bottomHorizontalLine.y, left: bottomHorizontalLine.x, width: bottomHorizontalLine.w }" class="horizontal-line horizontal-line-bottom">
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'callflow-toggle-action',
  props: {
    trigger: {
      type: Boolean,
      requierd: true
    },
    id: {
      type: String,
      requierd: true
    }
  },
  data: () => {
    return {
      showLines: false,
      topHorizontalLine: {
        x: '0px',
        y: '0px',
        w: '0px'
      },
      verticalLine: {
        x: '20px',
        y: '0px',
        w: '150px'
      },
      bottomHorizontalLine: {
        x: '-130px',
        y: '150px',
        w: '150px'
      }
    }
  },
  computed: {
    ...mapGetters('callflow', ['lineCoords'])
  },
  watch: {
    lineCoords: {
      deep: true,
      handler () {
        const id = this.id.replace('start_', '')
        if (!(id in this.lineCoords)) return

        this.showLines = true
        const coords = this.lineCoords[id]

        const [parent, type, child] = id.split('_')

        const parentData = Object.keys(this.lineCoords).filter(e => e.split('_')[0] === parent)

        const offsetX = parentData.length - parentData.findIndex(e => e === id)

        this.topHorizontalLine.w = `${offsetX * 15}px`

        this.verticalLine.x = `${offsetX * 15}px`
        this.verticalLine.w = coords.y - 5 + 'px'

        this.bottomHorizontalLine.w = coords.x + offsetX * 10 + 'px'
        this.bottomHorizontalLine.x = -(coords.x + offsetX * 10 - offsetX * 15) + 'px'
        this.bottomHorizontalLine.y = coords.y - 5 + 'px'
      }
    }
  }
  /* mounted () {
    console.log(this.id, 'callflow-toggle-action')
  } */
}
</script>

<style scoped>
.toggle-action-wrapper {
  position: absolute;
  right: 0;
  top: 50%;
}
.toggle-action-wrapper hr {
  border: none;
  background: #919EAB;
  position: absolute;
  margin-top: 5px;
  margin-left: 60px;
}
.vertical-line {
  width: 2px;
}
.horizontal-line {
  height: 2px;
}
.horizontal-line-bottom {
  margin-left: 62px !important;
}
</style>
