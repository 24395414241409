<template>
  <callflow-step
    :hang-up-option="hangUpOption"
    :path="path"
    icon="phone"
    step-name="Dial"
    @insertStepComponent="insertStep"
    @deleteStepIndex="$emit('deleteStepIndex')"
  >
    <v-row class="my-0">
      <v-col
        cols="12"
        md="12"
        class="px-7 pt-0 pb-2"
      >
        <v-row>
          <v-col cols="12" md="4" class="fz-14">Destination Number</v-col>
          <v-col
            cols="12"
            md="6"
            class="pt-2"
          >
            <v-radio-group
              v-model="sipUrl"
              class="round-radio--button d-flex ma-0"
              hide-details
              row
            >
              <v-radio
                v-for="(radio, index) in numberTypes"
                :key="index + 'radio'"
                :label="radio.label"
                :value="radio.value"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col
            cols="12"
            md="12"
            class="py-2 mb-4 d-flex align-center gap-12"
            style="padding-right:27.34px"
          >
            <!-- <v-select
              v-if="!sipUrl"
              :value="areaCode"
              :items="numberList"
              menu-props="offsetY"
              solo
              outlined
              hide-details
              class="pa-0 ma-0 input-area-code"
              @change="selectArea"
            >
              <template v-slot:append>
                <v-icon size="15px">unfold_more</v-icon>
              </template>
            </v-select> -->
            <v-text-field
              :value="dialNumber"
              :label="sipUrl ? 'SIP URL' : 'Number'"
              :rules="sipUrl ? sipRules : phoneRules"
              :maxlength="sipUrl ? '' : 11"
              solo
              outlined
              dense
              hide-details
              type="text"
              class="w-100"
              @input="setDestNumber($event)"
              @keypress="sipUrl ? filterSpacebar($event) : IsNumber($event)"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>

      <!-- <v-col
        cols="12"
        md="12"
        class="px-7 pt-0 pb-4"
      >
        <v-checkbox
          v-model="amd"
          label="Prevent voicemails and automated systems from answering a call."
          class="checkbox mt-1"
        ></v-checkbox>
      </v-col> -->

      <v-col
        cols="12"
        mb="12"
        class="px-3 pb-0 pt-0"
      >
        <v-alert class="violet-alert d-flex align-center fz-14 mb-2" :class="{ 'mobile-timeout' : $vuetify.breakpoint.smAndDown}">
          <span>If the destination does not answer within</span>

          <v-select
            v-model="timeout"
            :items="destinationTime"
            item-text="label"
            item-value="value"
            class="pa-0 ml-1 mr-2"
            style="max-width: 140px; height: 36px;"
            menu-props="offsetY"
            solo
            outlined
            hide-details
          >
            <template v-slot:append>
              <v-icon size="15px">unfold_more</v-icon>
            </template>
          </v-select>

          <span>then go to the next step.</span>
        </v-alert>
      </v-col>
    </v-row>
  </callflow-step>
</template>

<script>
import CallflowStep from './CallflowStep'
import areaCodes from '@/imports/areaCodes'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'CallflowDial',
  components: { CallflowStep },
  props: {
    hangUpOption: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: 0
    },
    path: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    destinationTime: [
      {
        label: '5 seconds',
        value: 5000
      },
      {
        label: '10 seconds',
        value: 10000
      },
      {
        label: '15 seconds',
        value: 15000
      },
      {
        label: '20 seconds',
        value: 20000
      }
    ],
    sipUrl: null,
    numberTypes: [
      {
        label: 'Number',
        value: false
      },
      {
        label: 'SIP URL',
        value: true
      }
    ],
    phoneRules: [
      v => !!v || 'Phone number is required',
      v => /^[0-9]+$/.test(v) || 'Phone number must be valid'
    ],
    sipRules: [
      v => !!v || 'SIP URL is required',
      v => !/^[ ]+$/.test(v) || 'SIP URL must be valid'
    ]
  }),
  computed: {
    ...mapGetters('callflow', ['getFromPath']),
    dialNumber () {
      return this.getFromPath(
        this.path.concat('payload', 'dialNumber')
      )// .slice(this.sipUrl ? 0 : 4)
    },
    areaCode () {
      return this.getFromPath(
        this.path.concat('payload', 'dialNumber')
      ).slice(1, 4)
    },
    amd: {
      get () {
        return this.getFromPath(
          this.path.concat('payload', 'amd')
        )
      },
      set (value) {
        this.setStepValue({
          path: this.path.concat('payload', 'amd'),
          value
        })
      }
    },
    timeout: {
      get () {
        return this.getFromPath(
          this.path.concat('payload', 'timeout')
        )
      },
      set (value) {
        this.setStepValue({
          path: this.path.concat('payload', 'timeout'),
          value
        })
      }
    },
    numberList () {
      return areaCodes.map(item => item.toString())
    }
  },
  watch: {
    sipUrl (newVal, oldVal) {
      if (oldVal === null) return
      this.setStepValue({
        path: this.path.concat('payload', 'dialNumber'),
        value: ''// newVal ? '' : '1201'
      })
    }
  },
  created () {
    if (this.dialNumber) {
      this.sipUrl = !/^\d+$/.test(this.dialNumber)
    } else {
      this.sipUrl = false
    }
  },
  methods: {
    ...mapMutations('callflow', [
      'setStepValue'
    ]),
    setDestNumber (value) {
      this.setStepValue({
        path: this.path.concat('payload', 'dialNumber'),
        value// : this.sipUrl ? value : '1' + this.areaCode + value
      })
    },
    selectArea (value) {
      this.setStepValue({
        path: this.path.concat('payload', 'dialNumber'),
        value: '1' + value + this.dialNumber
      })
    },
    insertStep (component) {
      this.$emit('insertStepComponent', { name: component.name, index: this.index })
    },
    filterSpacebar (event) {
      if (/^[ ]+$/.test(event.key)) return event.preventDefault()
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-timeout ::v-deep .v-alert__content {
  justify-content: center;
  font-weight: 400 !important;
  color: black !important;
  & > * {
    width: 100%;
    text-align: center;
  }
}
.input-area-code {
  max-width: 150px;
  ::v-deep .v-input__control {
    min-height: 38px !important;
  }
}
</style>
