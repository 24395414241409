<template>
  <callflow-step
    :hang-up-option="hangUpOption"
    :path="path"
    icon="map"
    step-name="Geo-routing"
    @insertStepComponent="insertStep"
    @deleteStepIndex="$emit('deleteStepIndex')"
  >
    <v-row class="my-0">
      <v-col
        cols="12"
        md="12"
        class="px-3 pt-0 pb-4"
      >
        <v-tabs
          v-model="geoRouting.currentTabs"
          class="px-5"
        >
          <v-tab
            v-for="tab in geoRouting.tabs"
            :key="tab"
            :class="{'mobile-tabs font-weight-medium': !isDesktop}"
          >
            {{ tab }}
          </v-tab>
        </v-tabs>
      </v-col>

      <v-col
        cols="12"
        md="12"
        class="px-3 py-0"
      >
        <v-row v-if="error" class="px-7 pt-3" style="width: 100%">
          <v-col class="pa-0">
            <p v-if="unknownZip" class="red--text">Unknown ZIP code.</p>
            <p v-else class="red--text">Codes must be different.</p>
          </v-col>
        </v-row>
        <v-tabs-items
          class="overflow-visible"
          v-model="geoRouting.currentTabs"
        >
          <v-tab-item>
            <v-card flat>
              <v-col
                cols="12"
                class="px-3"
              >
                <!-- md="12" -->
                <v-row
                  v-for="(location, locationIdx) in georoutingAreaCode"
                  :key="locationIdx"
                >
                  <v-col
                    cols="12"
                    md="12"
                    class="pa-0 actions-row geo-actions mb-3"
                  >
                    <v-alert
                      :class="areaActions.length <= locationIdx || !areaActions[locationIdx] ||areaActions[locationIdx].type < 0 ? 'error-alert' : 'info-alert'"
                      class="mb-0 fz-14"
                      tile
                      close-icon="close"
                    >
                      <v-row>
                        <v-col
                          cols="7"
                          lg="9"
                          md="8"
                          class="py-0 align-center d-flex"
                        >
                          <div>
                            Location {{ locationIdx + 1 }}:
                            <span class="ml-1" style="color: #637381;">If the caller’s area code matches the following area codes</span>
                          </div>
                        </v-col>

                        <v-col
                          cols="3"
                          md="2"
                          class="pa-0 d-flex justify-end"
                        >
                          <v-btn
                            v-if="areaActions.length <= locationIdx || !areaActions[locationIdx] || areaActions[locationIdx].type < 0"
                            class="circle"
                            text
                            icon
                            @click="setAction(['georoutingAreaCode', locationIdx])"
                          >
                            <v-icon size="20px">
                              warning
                            </v-icon>
                          </v-btn>
                          <v-select
                            v-else
                            :value="areaActions[locationIdx].type"
                            :items="stepList"
                            item-text="label"
                            item-value="value"
                            menu-props="offsetY"
                            class="mr-1"
                            solo
                            outlined
                            hide-details
                            style="height:38px;min-width:115px;margin-top:2px"
                            @change="changeActionType($event, ['georoutingAreaCode', locationIdx])"
                          >
                            <template v-slot:append>
                              <v-icon size="15px">unfold_more</v-icon>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col
                          cols="1"
                          md="1"
                          class="py-0 ml-sm-6 ml-md-0 ml-lg-0 pl-4"
                        >
                          <v-btn
                            :disabled="error"
                            text
                            icon
                            @click="removeGeoRouting('georoutingAreaCode', locationIdx)"
                          >
                            <v-icon>
                              close
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-alert>
                    <callflow-toggle-action
                      v-if="areaActions.length > locationIdx && areaActions[locationIdx] && areaActions[locationIdx].type >= 0"
                      :trigger="isActionVisible(['georoutingAreaCode', locationIdx])"
                      :id="`start_${path[0]}_georoutingAreaCode_${locationIdx}`"
                      @toggle="toggleActionVisibility(['georoutingAreaCode', locationIdx])" />
                  </v-col>

                  <v-row
                    class="px-4 pt-4"
                  >
                    <v-col
                      v-for="(item, locationItemIndex) in location"
                      :key="locationItemIndex"
                      cols="3"
                      md="2"
                      style="min-width:120px"
                      class="align-center mt-1 d-flex py-1"
                    >
                      <v-select
                        :value="location[locationItemIndex]"
                        :items="areaList(locationIdx)"
                        item-text="value"
                        item-value="value"
                        item-disabled="disabled"
                        solo
                        outlined
                        menu-props="offsetY"
                        hide-details
                        label="Area"
                        class="pa-0 area-select"
                        dense
                        style="min-width:80px"
                        @keydown="$forceUpdate()"
                        @change="selectArea($event, locationIdx, locationItemIndex)"
                      >
                        <template v-slot:append>
                          <v-icon size="15px">unfold_more</v-icon>
                        </template>
                      </v-select>
                      <v-btn
                        :disabled="error"
                        text
                        icon
                        @click="removeField('georoutingAreaCode', locationIdx, locationItemIndex, location[locationItemIndex])"
                      >
                        <v-icon>
                          close
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-col
                    cols="12"
                    md="12"
                    class="pt-0 px-4 pb-2"
                  >
                    <v-btn
                      :disabled="disableNewArea(location) || error"
                      text
                      class="text-none d-flex align-center px-2 mt-4"
                      height="36px"
                      @click="addLocation('georoutingAreaCode', locationIdx)"
                    >
                      <v-icon size="24px" color="#919EAB">add</v-icon>
                      Add area code
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                md="12"
                class="px-4 pb-0 pt-1 d-flex d-md-block align-center justify-center"
              >
                <v-btn
                  :disabled="error"
                  :height="!isDesktop ? '41px' : ''"
                  :style="{ width: !isDesktop ?'calc(100% - 29px)' : 'max-content' }"
                  class="violet-button"
                  @click="newGeoRouting('georoutingAreaCode')"
                >
                  Add location
                </v-btn>
              </v-col>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card flat>
              <v-col
                cols="12"
                class="px-3"
              >
                <!-- md="12" -->
                <v-row
                  v-for="(location, locationIdx) in georoutingZip"
                  :key="locationIdx"
                >
                  <v-col
                    cols="12"
                    md="12"
                    class="pa-0 actions-row geo-actions mb-3"
                  >
                    <v-alert
                      :class="zipActions.length <= locationIdx || !zipActions[locationIdx] || zipActions[locationIdx].type < 0 ? 'error-alert' : 'info-alert'"
                      class="mb-0 fz-14"
                      tile
                      close-icon="close"
                    >
                      <v-row>
                        <v-col
                          cols="7"
                          lg="9"
                          md="8"
                          class="py-0 align-center d-flex"
                        >
                          <div>
                            Location {{ locationIdx + 1 }}:
                            <span class="ml-1" style="color: #637381;">If the caller’s ZIP code matches the following ZIP codes</span>
                          </div>
                        </v-col>

                        <v-col
                          cols="3"
                          md="2"
                          class="pa-0 d-flex justify-end"
                        >
                          <v-btn
                            v-if="zipActions.length <= locationIdx || !zipActions[locationIdx] || zipActions[locationIdx].type < 0"
                            class="circle"
                            text
                            icon
                            @click="setAction(['georoutingZip', locationIdx])"
                          >
                            <v-icon size="20px">
                              warning
                            </v-icon>
                          </v-btn>
                          <v-select
                            v-else
                            :value="zipActions[locationIdx].type"
                            :items="stepList"
                            item-text="label"
                            item-value="value"
                            item-disabled="disabled"
                            menu-props="offsetY"
                            solo
                            outlined
                            hide-details
                            style="height:38px;min-width:115px;margin-top:2px"
                            @change="changeActionType($event, ['georoutingZip', locationIdx])"
                          >
                            <template v-slot:append>
                              <v-icon size="15px">unfold_more</v-icon>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col
                          cols="1"
                          md="1"
                          class="py-0 ml-sm-6 ml-md-0 ml-lg-0 pl-4"
                        >
                          <v-btn
                            :disabled="error"
                            text
                            icon
                            @click="removeGeoRouting('georoutingZip', locationIdx)"
                          >
                            <v-icon>
                              close
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-alert>
                    <callflow-toggle-action
                      v-if="zipActions.length > locationIdx && zipActions[locationIdx] && zipActions[locationIdx].type >= 0"
                      :trigger="isActionVisible(['georoutingZip', locationIdx])"
                      :id="`start_${path[0]}_georoutingZip_${locationIdx}`"
                      @toggle="toggleActionVisibility(['georoutingZip', locationIdx])" />
                  </v-col>
                  <v-row
                    class="px-4 pt-4"
                  >
                    <v-col
                      v-for="(item, locationItemIndex) in location"
                      :key="locationItemIndex"
                      cols="3"
                      md="3"
                      style="min-width:150px"
                      class="align-center mt-1 d-flex py-1"
                    >
                      <v-text-field
                        :value="location[locationItemIndex]"
                        :maxlength="5"
                        solo
                        outlined
                        type="text"
                        dense
                        hide-details
                        @keypress="IsNumber($event)"
                        @input="setZip($event, locationIdx, locationItemIndex)"
                      ></v-text-field>
                      <v-btn
                        text
                        icon
                        :disabled="error"
                        @click="removeField('georoutingZip', locationIdx, locationItemIndex)"
                      >
                        <v-icon>
                          close
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-col
                    cols="12"
                    md="12"
                    class="pt-0 px-4 pb-2"
                  >
                    <v-btn
                      :disabled="disableNewZip(location) || error"
                      text
                      class="text-none d-flex align-center px-2 mt-4"
                      height="36px"
                      @click="addLocation('georoutingZip', locationIdx)"
                    >
                      <v-icon size="24px" color="#919EAB">add</v-icon>
                      Add ZIP code
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                md="12"
                class="px-4 pb-0 pt-1"
              >
                <v-btn
                  :disabled="error"
                  class="violet-button"
                  @click="newGeoRouting('georoutingZip')"
                >
                  Add location
                </v-btn>
              </v-col>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>

      <v-col
        cols="12"
        md="12"
        class="actions-row"
      >
        <v-alert
          :class="!otherAction || otherAction.type < 0 ? 'error-alert' : 'info-alert'"
          style="padding-block:4px !important"
          tile
          class="mb-0 fz-14"
        >
          <v-col cols="9" md="9">Any other time</v-col>
          <v-col
            cols="3"
            md="3"
            class="pa-0 ma-0 d-flex align-center justify-end"
          >
            <v-btn
              v-if="!otherAction || otherAction.type < 0"
              class="circle"
              text
              icon
              @click="setAction(['other'])"
            >
              <v-icon size="20px">
                warning
              </v-icon>
            </v-btn>
            <v-select
              v-else
              :value="otherAction.type"
              :items="stepList"
              item-text="label"
              item-value="value"
              item-disabled="disabled"
              menu-props="offsetY"
              solo
              outlined
              hide-details
              style="height:38px;min-width:115px;margin-top:2px"
              @change="changeActionType($event, ['other'])"
            >
              <template v-slot:append>
                <v-icon size="15px">unfold_more</v-icon>
              </template>
            </v-select>
          </v-col>
        </v-alert>
        <callflow-toggle-action
          v-if="otherAction && otherAction.type >= 0"
          :trigger="isActionVisible(['other'])"
          :id="`start_${path[0]}_other`"
          @toggle="toggleActionVisibility(['other'])" />
      </v-col>
    </v-row>
    <!-- <template v-slot:message>The caller’s area code is different than any location above</template> -->
  </callflow-step>
</template>

<script>
import CallflowStep from './CallflowStep'
import CallflowToggleAction from './CallflowToggleAction'
import areaCodes from '@/imports/areaCodes'
import zipCodes from '@/imports/zipCodes'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'CallflowGeoRouting',
  components: { CallflowStep, CallflowToggleAction },
  props: {
    hangUpOption: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: 0
    },
    path: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    geoRouting: {
      tabs: ['Area code', 'ZIP code'],
      currentTabs: null
    },
    error: false,
    unknownZip: false,
    disabledAreas: [[]],
    stepListData: [
      { value: 0, label: 'Greeting' },
      { value: 1, label: 'Dial' },
      { value: 2, label: 'Simulcall' },
      // { value: 6, label: 'Tag' },
      { value: 8, label: 'HangUp' }
    ]
  }),
  computed: {
    ...mapGetters('callflow', ['getFromPath', 'steps', 'hasHangUp']),
    georoutingAreaCode () {
      return this.getFromPath(
        this.path.concat('payload', 'georoutingAreaCode')
      )
    },
    georoutingZip () {
      return this.getFromPath(
        this.path.concat('payload', 'georoutingZip')
      )
    },
    areaActions () {
      return this.getFromPath(this.path.concat('actions', 'georoutingAreaCode'))
    },
    zipActions () {
      return this.getFromPath(this.path.concat('actions', 'georoutingZip'))
    },
    otherAction () {
      return this.getFromPath(this.path.concat('actions', 'other'))
    },
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    stepList () {
      return this.stepListData.map(item => ({
        ...item,
        disabled: item.value === 8 && this.hasHangUp
      }))
    },
    children () {
      return this.getFromPath(this.path.concat('children'))
    }
  },
  watch: {
    'geoRouting.currentTabs' (value) {
      this.setStepValue({
        path: this.path.concat('activeTab'),
        value
      })
    }
  },
  created () {
    if (this.$route.name === 'EditCallflow') {
      this.geoRouting.currentTabs = this.getFromPath(this.path.concat('activeTab'))
      this.disabledAreas = this.georoutingAreaCode
    }
  },
  methods: {
    ...mapMutations('callflow', [
      'setStepValue',
      'pushToStep',
      'removeFromStep',
      'setError'
    ]),
    ...mapActions('callflow', ['addStep', 'calculateLines']),
    areaList (index) {
      return areaCodes.map(item => ({
        value: item,
        disabled: this.disabledAreas[index].includes(item)
      }))
    },
    removeGeoRouting (type, locationIndex) {
      const action = type === 'georoutingAreaCode' ? this.areaActions[locationIndex] : this.zipActions[locationIndex]
      const child = this.steps.findIndex(e => e.id === action.id)
      const childrenIndex = this.getFromPath(this.path).children.findIndex(e => e === action.id)
      this.removeFromStep({
        path: this.path.concat('actions', type, locationIndex)
      })
      this.removeFromStep({
        path: this.path.concat('payload', type, locationIndex)
      })
      if (child < 0) return
      this.removeFromStep({
        path: this.path.concat('children', childrenIndex)
      })
      this.removeFromStep({
        path: [child]
      })
      if (type === 'georoutingAreaCode') {
        this.disabledAreas.splice(locationIndex, 1)
      }
      setTimeout(() => {
        this.calculateLines()
      }, 500)
    },
    removeField (type, locationIndex, locationItemIndex, value) {
      if (type === 'georoutingAreaCode') {
        this.disabledAreas[locationIndex] = this.disabledAreas[locationIndex].filter(e => e !== value)
      }

      this.removeFromStep({
        path: this.path.concat('payload', type, locationIndex, locationItemIndex)
      })
      setTimeout(() => {
        this.calculateLines()
      }, 500)
    },
    selectArea (value, locationIndex, locationItemIndex) {
      const selected = this.getFromPath(this.path.concat('payload', 'georoutingAreaCode', locationIndex, locationItemIndex))
      if (this.disabledAreas[locationIndex].includes(selected)) {
        this.disabledAreas[locationIndex] = this.disabledAreas[locationIndex].filter(e => e !== selected)
      }
      this.disabledAreas[locationIndex].push(value)
      this.setStepValue({
        path: this.path.concat('payload', 'georoutingAreaCode', locationIndex, locationItemIndex),
        value
      })
      const location = this.getFromPath(this.path.concat('payload', 'georoutingAreaCode', locationIndex))
      const error = location.findIndex((item, index) => value && index !== locationItemIndex && item === value) >= 0
      this.error = error
      this.setError({ path: 'sameArea', error })
      setTimeout(() => {
        this.calculateLines()
        this.$forceUpdate()
      }, 500)
    },
    setZip (value, locationIndex, locationItemIndex) {
      const strValue = value.toString()
      let fullString = ''
      for (let i = 0; i < 5 - strValue.length; i++) {
        fullString += '0'
      }
      fullString += strValue

      this.unknownZip = !zipCodes.includes(fullString)

      this.setError({ path: 'unknownZip', error: this.unknownZip })

      this.setStepValue({
        path: this.path.concat('payload', 'georoutingZip', locationIndex, locationItemIndex),
        value
      })
      const location = this.getFromPath(this.path.concat('payload', 'georoutingZip', locationIndex))
      const error = location.findIndex((item, index) => value && index !== locationItemIndex && item === value) >= 0
      this.error = error || this.unknownZip
      this.setError({ path: 'sameZip', error })
      this.disableNewZip(location)
      setTimeout(() => {
        this.calculateLines()
      }, 500)
    },
    setDisabledAreas (index) {
      this.areaList[index] = this.areaList[index].map(item => ({
        ...item,
        disabled: this.disabledAreas[index].includes(item.value)
      }))
    },
    addLocation (type, index) {
      this.pushToStep({
        path: this.path.concat(['payload', type, index]),
        value: ''
      })
      console.log(this.children, 'children')
      setTimeout(() => {
        this.calculateLines()
      }, 500)
    },
    newGeoRouting (type) {
      this.pushToStep({
        path: this.path.concat(['payload', type]),
        value: ['']
      })
      this.disabledAreas = [...this.disabledAreas, []]
      this.pushToStep({
        path: this.path.concat(['actions', type]),
        value: { name: '', type: -1 }
      })
      setTimeout(() => {
        this.calculateLines()
      }, 500)
    },
    insertStep (component) {
      this.$emit('insertStepComponent', { name: component.name, index: this.index })
    },
    setAction (path) {
      const id = Date.now()
      const name = path.length > 1 ? 'Branch' : ''
      this.setStepValue({
        path: this.path.concat('actions', path),
        value: { name, type: 0, id }
      })

      this._addStep(id, -1, 0, false, path[0])
      this.pushToStep({ path: this.path.concat('children'), value: id })
    },
    _addStep (id, index, stepListIndex, isReplacement, type) {
      console.log(id, index, stepListIndex, isReplacement, type)
      const parent = this.getFromPath(this.path)

      const name = 'Callflow' + this.stepList[stepListIndex].label

      const path = [this.path[this.path.length - 1] + (isReplacement ? 0 : 1) + (isReplacement ? index + 1 : parent.children.length)]

      if (index < 0) index = parent.children.length
      const message = { type: 'geoRouting', branch: type }

      this.addStep({ path, name, parent: parent.id, id, message, isReplacement })
      setTimeout(() => {
        this.calculateLines()
      }, 500)
    },
    changeActionType (value, path) {
      const id = Date.now()
      const name = path.length > 1 ? 'Branch' : ''
      const parent = this.getFromPath(this.path)
      const action = this.getFromPath(this.path.concat('actions', path))
      const childIndex = parent.children.findIndex(e => e === action.id)
      const stepListIndex = this.stepList.findIndex(e => e.value === value)
      this.setStepValue({
        path: this.path.concat('actions', path),
        value: { name, type: value, id }
      })
      this._addStep(id, childIndex, stepListIndex, true, path[0])
      this.setStepValue({
        path: this.path.concat('children', childIndex),
        value: id
      })
    },
    isActionVisible (path) {
      const action = this.getFromPath(this.path.concat('actions', path))
      if (!action) return false
      const children = this.steps.find(e => e.id === action.id)
      return !children || children.isVisible
    },
    toggleActionVisibility (path) {
      const parent = this.getFromPath(this.path)
      const action = this.getFromPath(this.path.concat('actions', path))
      const childIndex = parent.children.findIndex(e => e === action.id)
      const childPath = [this.path[this.path.length - 1] + childIndex + 1]
      const child = this.getFromPath(childPath)
      this.setStepValue({
        path: childPath.concat('isVisible'),
        value: !child.isVisible
      })
    },
    disableNewArea (arr) {
      return arr.some(e => !e)
    },
    disableNewZip (arr) {
      return arr.some(e => !e)
    }
  }
}
</script>

<style scoped lang="scss">
.area-select ::v-deep .v-select__selection--disabled {
  color: #212B36 !important;
}
</style>
