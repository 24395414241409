<template>
  <callflow-step
    :hang-up-option="hangUpOption"
    :path="path"
    icon="call_end"
    icon-color="#ED6347"
    step-name="Hang Up"
    @insertStepComponent="insertStep"
    @deleteStepIndex="$emit('deleteStepIndex')"
  >
    <v-col
      :class="{ 'grey--text': $vuetify.breakpoint.smAndDown }"
      cols="12"
      md="12"
      class="px-4 fz-14"
    >
      {{ hangUp.call }}
    </v-col>
  </callflow-step>
</template>

<script>
import CallflowStep from './CallflowStep'
export default {
  name: 'HangUp',
  components: { CallflowStep },
  props: {
    hangUpOption: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: null
    },
    path: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    hangUp: {
      call: 'The call will be disconnected.'
    }
  }),
  methods: {
    insertStep (component) {
      this.$emit('insertStepComponent', { name: component.name, index: this.index })
    }
  }
}
</script>
