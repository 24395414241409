<template>
  <callflow-step
    :hang-up-option="hangUpOption"
    :path="path"
    icon="ring_volume"
    step-name="Simulcall"
    @insertStepComponent="insertStep"
    @deleteStepIndex="$emit('deleteStepIndex')"
  >
    <v-row class="my-0">
      <v-col
        cols="12"
        md="12"
        class="px-7 pt-2 pb-4"
      >
        <v-radio-group
          v-model="simulCallAll"
          class="round-radio--button d-flex ma-0"
          hide-details
          row
        >
          <v-radio
            v-for="(radio, index) in radioGroup"
            :key="index + 'radio'"
            :label="radio.label"
            :value="radio.value"
          ></v-radio>
        </v-radio-group>
      </v-col>

      <v-col
        cols="12"
        md="12"
        class="px-7 pt-0 pb-2"
      >
        <v-row class="pa-0 ma-0 align-center">
          <v-col cols="12" md="4" class="fz-14 py-1">Destination Numbers</v-col>
          <v-col
            cols="12"
            md="6"
            class="py-1"
          >
            <v-radio-group
              v-model="sipUrl"
              class="round-radio--button d-flex ma-0"
              hide-details
              row
            >
              <v-radio
                v-for="(radio, index) in numberTypes"
                :key="index + 'radio'"
                :label="radio.label"
                :value="radio.value"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row
          v-for="(number, index) in simulCallDestinations"
          :key="index"
          class="ma-0 relative"
        >
          <v-col
            cols="12"
            class="py-2"
            :md="sipUrl ? 11 : 7"
            :style="sipUrl ? '' : 'padding-right:39px'"
          >
            <v-text-field
              :value="number.number"
              :maxlength="sipUrl ? -1 : 11"
              :class="{ 'is-duplicate': checkDuplicate(number.number, index) }"
              solo
              outlined
              type="text"
              dense
              hide-details
              @input="setDestNumber(index, $event)"
              @keypress="sipUrl ? isSip($event) : IsNumber($event)"
            >
            <template v-if="sipUrl" v-slot:prepend-inner>
              sip://
            </template>
          </v-text-field>
          </v-col>

          <v-col
            cols="1"
            :md="sipUrl ? 1 : 5"
            class="text-right py-2"
            :class="{ 'mobile-number-remove' : isMobile}"
          >
            <v-btn
              text
              icon
              @click="removeSimucallNumber(index)"
            >
              <v-icon color="#919EAB">remove_circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col
            cols="12"
            md="12"
            class="pt-0 pb-2"
          >
            <v-btn
              text
              class="text-none d-flex align-center px-2 font-weight-regular"
              height="36px"
              @click="addSimucallNumber"
            >
              <v-icon
                class="mr-1"
                color="#919EAB"
              >
                add
              </v-icon>
              <span>Add {{ sipUrl ? 'SIP URL' : 'number'}}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <!-- <v-col
        cols="12"
        md="12"
        class="px-7 pt-0 pb-1"
      >
        <v-checkbox
          v-model="amd"
          label="Prevent voicemails and automated systems from answering a call."
          class="checkbox mt-1"
        ></v-checkbox>
      </v-col>

      <v-col
        cols="12"
        md="12"
        class="px-7 pt-0 pb-4"
      >
        <v-checkbox
          v-model="simulCallSticky"
          label="Route previous callers to the number that answered last time they called."
          class="checkbox mt-1"
        ></v-checkbox>
      </v-col> -->

      <v-col
        cols="12"
        mb="12"
        class="px-3 pb-0 pt-0"
      >
        <v-alert
          class="violet-alert d-flex align-center fz-14 mb-2"
          :class="{ 'mobile-timeout' : isMobile}"
        >
          <span :class="isMobile ? 'fz-12' : 'fz-14'" class="font-weight-regular black-text">
            If the destination does not answer within
          </span>

          <v-select
            v-model="timeout"
            :items="destinationTime"
            item-text="label"
            item-value="value"
            class="pa-0 ml-1 mr-2 font-weight-regular"
            style="max-width: 140px; height: 36px;"
            menu-props="offsetY"
            solo
            outlined
            hide-details
          >
            <template v-slot:append>
              <v-icon size="15px">unfold_more</v-icon>
            </template>
          </v-select>

          <span :class="isMobile ? 'fz-12' : 'fz-14'" class="font-weight-regular black-text">then go to the next step.</span>
        </v-alert>
      </v-col>
    </v-row>
  </callflow-step>
</template>

<script>
import CallflowStep from './CallflowStep'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'CallflowSimulcall',
  components: { CallflowStep },
  props: {
    hangUpOption: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: 0
    },
    path: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    radioGroup: [
      {
        label: 'Ring in sequence',
        value: false
      },
      {
        label: 'Ring all at once',
        value: true
      }
    ],
    sipUrl: null,
    numberTypes: [
      {
        label: 'Number',
        value: false
      },
      {
        label: 'SIP URL',
        value: true
      }
    ],
    destinationTime: [
      {
        label: '5 seconds',
        value: 5000
      },
      {
        label: '10 seconds',
        value: 10000
      },
      {
        label: '15 seconds',
        value: 15000
      },
      {
        label: '20 seconds',
        value: 20000
      }
    ]
  }),
  computed: {
    ...mapGetters('callflow', ['getFromPath']),
    simulCallDestinations () {
      return this.getFromPath(
        this.path.concat('payload', 'simulCallDestinations')
      ).map(e => ({
        number: this.sipUrl ? e.number.replace('sip://', '') : e.number
      }))
    },
    simulCallAll: {
      get () {
        return this.getFromPath(
          this.path.concat('payload', 'simulCallAll')
        )
      },
      set (value) {
        this.setStepValue({
          path: this.path.concat('payload', 'simulCallAll'),
          value
        })
      }
    },
    amd: {
      get () {
        return this.getFromPath(
          this.path.concat('payload', 'amd')
        )
      },
      set (value) {
        this.setStepValue({
          path: this.path.concat('payload', 'amd'),
          value
        })
      }
    },
    simulCallSticky: {
      get () {
        return this.getFromPath(
          this.path.concat('payload', 'simulCallSticky')
        )
      },
      set (value) {
        this.setStepValue({
          path: this.path.concat('payload', 'simulCallSticky'),
          value
        })
      }
    },
    timeout: {
      get () {
        return this.getFromPath(
          this.path.concat('payload', 'timeout')
        )
      },
      set (value) {
        this.setStepValue({
          path: this.path.concat('payload', 'timeout'),
          value
        })
      }
    },
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    hasDuplicate () {
      return this.simulCallDestinations.some((item, idx) =>
        item.number && this.simulCallDestinations.findIndex(e => e.number && e.number === item.number) !== idx)
    }
  },
  watch: {
    sipUrl (newVal, oldVal) {
      if (oldVal === null) return
      this.setStepValue({
        path: this.path.concat('payload', 'simulCallDestinations'),
        value: []
      })
    }
  },
  created () {
    if (this.simulCallDestinations.length) {
      this.sipUrl = !this.simulCallDestinations.some(s => /^\d+$/.test(s.number))
    } else {
      this.sipUrl = false
    }
  },
  methods: {
    ...mapMutations('callflow', [
      'setStepValue',
      'pushToStep',
      'removeFromStep'
    ]),
    removeSimucallNumber (index) {
      this.removeFromStep({
        path: this.path.concat('payload', 'simulCallDestinations', index)
      })
    },
    addSimucallNumber () {
      if (this.simulCallDestinations.some(e => !e.number)) {
        return this.$snotify.error(`Please fill all fields before adding new ${this.sipUrl ? 'SIP URL' : 'number'}`)
      }
      if (this.hasDuplicate) return this.$snotify.error(`Please remove duplicate ${this.sipUrl ? 'SIP URL' : 'number'}`)
      this.pushToStep({
        path: this.path.concat('payload', 'simulCallDestinations'),
        value: { number: '' }
      })
    },
    setDestNumber (index, value) {
      this.setStepValue({
        path: this.path.concat('payload', 'simulCallDestinations', index, 'number'),
        value: this.sipUrl ? 'sip://' + value : value
      })
    },
    insertStep (component) {
      this.$emit('insertStepComponent', { name: component.name, index: this.index })
    },
    checkDuplicate (number, index) {
      return this.simulCallDestinations.findIndex((e, idx) => idx !== index && e.number === number) >= 0
    },
    isSip (event) {
      if (!/^[A-Za-z\d.@:]+$/.test(event.key)) return event.preventDefault()
    }
  }
}
</script>
<style scoped lang="scss">
  ::v-deep .theme--light.v-label {
    color: #212B36 !important;
  }
  .mobile-timeout ::v-deep .v-alert__content {
    justify-content: center;
    font-weight: 600 !important;
    & > * {
      width: 100%;
      text-align: center;
    }
  }
  .mobile-number-remove {
    position: absolute;
    right: 7px;
    top: 0;
  }
  .v-btn.font-weight-regular ::v-deep .v-btn__content span {
    color: #212B36;
  }
  .is-duplicate ::v-deep .v-input__slot {
    border-color: red !important;
  }
  ::v-deep .v-input__prepend-inner {
    padding-right: 0 !important;
  }
  @media (max-width: 600px) {
    ::v-deep .theme--light.v-label {
      color: #8B8B8B !important;
    }
  }
</style>
