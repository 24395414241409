<template>
  <v-row class="align-center">
    <v-col
      v-if="textToSpeech"
      cols="12"
      md="12"
      class="pt-1 pb-0"
    >
      <v-select
        v-model="selectedVoice"
        :items="voices"
        item-text="name"
        item-value="name"
        label="Select voice"
        menu-props="offsetY"
        solo
        outlined
        hide-details
        class="pa-0"
      >
        <template v-slot:append>
          <v-icon size="15px">unfold_more</v-icon>
        </template>
      </v-select>
    </v-col>
    <v-col
      v-if="!forRecorder"
      :md="small ? 1 : 12"
      :class="small ? 'px-0' : 'justify-flex-start gap-10'"
      :cols="small ? 1 : 12"
      class="d-flex align-center"
    >
      <audio
        v-if="playingNow"
        :src="playingNow"
        ref="audio"
        @ended="ended"
        @loadedmetadata="recLoadedMetaData"
        @timeupdate="currentDuration = $refs.audio.currentTime"
      ></audio>
      <v-btn
        icon
        class="text-none d-flex align-center px-0"
        @click="togglePlaying"
      >
        <v-icon size="30" color="#5D6AC1">{{ paused ? 'play_arrow' : 'pause' }}</v-icon>
      </v-btn>
      <label v-if="!small" class="fz-14 d-block">{{ name }}</label>
    </v-col>
    <v-col
      :md="small ? 11 : 12"
      :cols="small ? 11 : 12"
      :class="{ 'justify-center': $vuetify.breakpoint.mdAndUp }"
      class="spectrum-container d-flex align-center"
    >
      <div class="spectrum d-flex align-center px-1">
        <span
          v-for="(height, index) in lineHeights"
          :key="index"
          :class="{ progress: !forRecorder && progress > 0 && index * 100 / length < progress + 1 }"
          :style="`height:${height}px`"
          class="spectrum-line"
        ></span>
      </div>
    </v-col>
    <v-col
      v-if="!forRecorder && !small && playingNow && textToSpeech === ''"
      cols="12"
      md="12"
      class="d-flex align-center justify-space-between pt-0"
    >
      <span class="fz-14">{{ toHourMinSec(Math.ceil(currentDuration)) }}</span>
      <span class="fz-14">{{ toHourMinSec(Math.ceil(audioDuration)) }}</span>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'audio-spectrum',
  props: {
    forRecorder: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    length: {
      type: Number,
      default: 120
    },
    textToSpeech: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: ''
    },
    recordingItem: {
      type: Object,
      default: () => {}
    },
    pause: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      synth: null,
      recordPlay: false,
      voices: [],
      selectedVoice: {},
      lineHeights: [],
      recordTimer: null,
      spectrumTimer: null,
      currentDuration: 0,
      audioDuration: 0,
      paused: true,
      playingNow: ''
    }
  },
  computed: {
    ...mapGetters('components', ['spectrumLines']),
    progress () {
      return this.currentDuration * 100 / this.audioDuration
    }
  },
  created () {
    this.playingNow = this.src
    this.lineHeights = this.spectrumLines(this.length)
    if (this.forRecorder || this.textToSpeech) this.setRecordTimer()
    if (this.textToSpeech) this.getSpeechVoices()
  },
  watch: {
    pause (val) {
      if (val) this.ended()
    },
    'synth.speaking' (newVal) {
      this.paused = !newVal
      if (!newVal) {
        this.synth.cancel()
        this.currentDuration = 0
      }
    },
    selectedVoice () {
      if (!this.textToSpeech) return
      this.playTextToSpeech()
      setTimeout(() => {
        this.playTextToSpeech()
      }, 50)
    }
  },
  methods: {
    ...mapActions(['getRecording']),
    getSpeechVoices () {
      this.audioDuration = this.textToSpeech.length / 19
      this.setSpeech()
        .then((voices) => {
          this.voices = voices
          this.selectedVoice = voices[0].name
        })
    },
    setSpeech () {
      const self = this
      return new Promise(
        function (resolve, reject) {
          self.synth = window.speechSynthesis
          const id = setInterval(() => {
            if (self.synth.getVoices().length !== 0) {
              resolve(self.synth.getVoices())
              clearInterval(id)
            }
          }, 10)
        }
      )
    },
    playTextToSpeech () {
      if (!this.synth || !this.selectedVoice) {
        this.getSpeechVoices()
        return
      }
      if (this.synth.speaking) {
        this.synth.cancel()
        this.paused = true
        this.currentDuration = 0
      } else {
        const voice = this.voices.find((v) => v.name === this.selectedVoice)
        if (!voice) return
        this.currentDuration = 0
        const utterThis = new SpeechSynthesisUtterance(this.textToSpeech)
        utterThis.voice = voice
        this.synth.speak(utterThis)
        this.paused = false
      }
    },
    setRecordTimer () {
      this.recordTimer = setInterval(() => {
        if (this.textToSpeech) this.paused = !this.synth.speaking
        if (this.forRecorder) this.lineHeights = this.spectrumLines(this.length)
      }, 1000)
      this.spectrumTimer = setInterval(() => {
        if (this.synth.speaking) this.currentDuration += 0.1
      }, 100)
    },
    async loadRecording () {
      await this.getRecording(this.recordingItem.uuid)
        .then((res) => {
          const blob = new Blob([res.data], { type: 'application/octet-stream' })
          this.playingNow = window.URL.createObjectURL(blob)
        }).catch(err => {
          throw new Error(err)
        })
    },
    recLoadedMetaData () {
      const self = this
      if (this.$refs.audio.duration === Infinity) {
        self.$refs.audio.currentTime = 1e101
        self.$refs.audio.ontimeupdate = function () {
          this.ontimeupdate = () => {
            self.audioDuration = self.$refs.audio.duration
          }
          self.$refs.audio.currentTime = 0
        }
      } else {
        this.audioDuration = self.$refs.audio.duration
      }
    },
    async togglePlaying () {
      if (this.textToSpeech) {
        this.playTextToSpeech()
        return
      }
      if (this.playingNow) {
        this.toggle()
        return
      }
      if (!this.recordingItem.uuid) return
      await this.loadRecording()
        .then(() => {
          setTimeout(() => {
            this.toggle()
          }, 200)
        })
    },
    toggle () {
      const audio = this.$refs.audio
      if (!audio) return
      if (audio.paused || this.paused) {
        this.$refs.audio.play()
        this.paused = false
        this.$emit('onPlay', this.recordingItem)
      } else {
        this.$refs.audio.pause()
        this.paused = true
      }
    },
    ended () {
      if (!this.$refs.audio) return
      this.$refs.audio.pause()
      this.paused = true
    }
  },
  destroyed () {
    clearInterval(this.recordTimer)
    clearInterval(this.spectrumTimer)
    this.recordTimer = null
    this.spectrumTimer = null
  }
}
</script>

<style scoped>
.spectrum {
  gap: 2px;
  width: max-content;
  justify-content: flex-end;
  overflow: hidden;
}
.spectrum-line {
  width: 3px;
  background: #CED4DA;
  border-radius: 50vh;
}
.spectrum-line.progress {
  background: #5D6AC1;
}
</style>
