<template>
  <v-sheet
    ref="dropzone"
    title="Drop or click to browse files from your computer"
    width="100%"
    height="150px"
    class="br-3 drop-sheet d-flex align-center justify-center"
    @drop.prevent="onDropFile($event)"
    @dragover.prevent
    @click="onClickFile"
  >
    <v-col
      cols="12"
      md="8"
      class="fz-14 text-center font-weight-semi-bold indigo--text"
      v-if="!uploadedFiles.length"
    >
      Drop or click here to browse files from your computer
    </v-col>

    <v-virtual-scroll
      v-else
      :items="uploadedFiles"
      height="150px"
      width="100%"
      item-height="50"
    >
      <template
        v-slot:default="{item, index}"
      >
        <v-list-item
          :key="item.name"
        >
          <v-list-item-content>
            <v-list-item-title
              class="fz-14 pa-0"
            >
              {{ item.name }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action
            class="ma-0"
          >
            <v-btn
              @click.stop="removeFile(index)"
              icon
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider class="v-divider__drop"></v-divider>
      </template>
    </v-virtual-scroll>

    <input
      type="file"
      style="display: none"
      ref="onClickFile"
      :accept="`${fileType}/*`"
      multiple
      @change="onClickFileLoad"
    />
  </v-sheet>
</template>

<script>
export default {
  name: 'DropOrClick',
  props: {
    fileType: {
      type: String,
      default: 'image'
    }
  },
  data: () => ({
    uploadedFiles: []
  }),
  methods: {
    onDropFile (event) {
      if (this.fileType === 'audio' && this.uploadedFiles.length > 0) return
      Array.from(event.dataTransfer.files).forEach(element => this.uploadedFiles.push(element))
      this.$emit('update:filesUploaded', this.uploadedFiles)
    },
    removeFile (index) {
      this.uploadedFiles.splice(index, 1)
      this.$emit('update:filesUploaded', this.uploadedFiles)
    },
    onClickFile () {
      if (this.fileType === 'audio' && this.uploadedFiles.length > 0) return
      this.$refs.onClickFile.click()
    },
    onClickFileLoad (event) {
      if (this.fileType === 'audio' && this.uploadedFiles.length > 0) return
      Array.from(event.target.files).forEach(element => this.uploadedFiles.push(element))
      this.$refs.onClickFile.value = ''
      this.$emit('update:filesUploaded', this.uploadedFiles)
    },
    reset () {
      this.uploadedFiles = []
      this.$emit('update:filesUploaded', this.uploadedFiles)
    }
  }
}
</script>

<style lang="scss" scoped>
.drop-sheet {
  border: 1px dashed #B3BCF5;
  border-radius: 3px;
  background-color: #F3F4FA;
  cursor: pointer;

  .v-divider__drop {
    border: 0;
    border-bottom: 1px dashed #B3BCF5;
  }
}
</style>
