<template>
<v-row class="my-0">
  <v-col
    cols="12"
    md="12"
    class="px-3 pt-0 pb-0"
  >
    <v-tabs
      v-model="currentTabs"
      class="px-4"
      :class="{ 'mobile-tabs': isMobile }"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab"
        :class="isMobile ? 'font-weight-bold fz-14' : 'font-weight-regular'"
        class="py-0 px-2"
      >
        {{ tab }}
      </v-tab>
    </v-tabs>
  </v-col>

  <v-col
    cols="12"
    md="12"
    class="pt-0 pb-4"
  >
    <v-tabs-items v-model="currentTabs">
<!-- Read message -->
      <v-tab-item>
        <v-card flat class="px-4">
          <v-card-text class="px-0">
            <label
              :style="{ color: isMobile ? '#8B8B8B !important' : '#212B36' }"
              class="fz-14 mb-1 d-block"
            >Read the following text to the caller</label>
            <v-textarea
              solo
              outlined
              label="Enter your text here"
              :value="recordMessage"
              :maxlength="1000"
              dense
              hide-details
              no-resize
              height="100px"
              @input="setRecordMessage"
            ></v-textarea>
          </v-card-text>
          <v-row class="ma-0 d-flex flex-column flex-lg-row">
            <v-col
              cols="12"
              md="4"
              sm="4"
              class="pt-1 pb-0 px-0"
            >
              <v-card-actions class="pa-0">
                <v-btn
                  :disabled="!recordMessage || !recordMessage.trim()"
                  class="grey-button px-2 w-100"
                  style="max-width:163px;min-width:163px"
                  @click="previewMessage = !previewMessage"
                >
                  <v-icon color="#919EAB">play_arrow</v-icon>
                  <span>Preview message</span>
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
          <v-row v-if="previewMessage">
            <v-col cols="12">
              <audio-spectrum
                :text-to-speech="recordMessage"
                :length="110"
                ref="recordMessageSpectrum"
                small
              />
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
<!-- Play Existing -->
      <v-tab-item>
        <v-card flat>
          <v-col
            cols="12"
            md="12"
            v-for="rec in recordingList"
            :key="rec.id"
            class="d-flex align-center py-0 px-4 recording-item flex-wrap"
            @click="selectRecording(rec)"
          >
            <v-col
              cols="12"
              md="3"
              class="d-flex align-center px-0"
            >
              <span
                :class="{selected: rec.selected}"
                class="selectRecording mr-2"
              ></span>
              <label class="fz-14">{{ rec.original_filename }}</label>
            </v-col>
            <v-col
              cols="12"
              md="8"
              class="px-0 py-2"
            >
              <audio-spectrum
                :recordingItem="rec"
                :length="75"
                :pause="!rec.selected"
                ref="playExisting"
                small
                @onPlay="selectRecording"
              />
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="d-flex align-center px-0"
            >
              <v-btn
                icon
                class="text-none d-flex align-center px-0"
                @click="openDeleteDialog(rec)"
              >
                <v-icon size="25" color="#5D6AC1">delete</v-icon>
              </v-btn>
            </v-col>
            <!-- <v-row class="mx-0">
              <v-radio-group v-model="recordingItem" mandatory>
                <v-radio
                  v-for="record in recordingListComputed"
                  :key="record.id"
                  :label="record.originalFilename"
                  :value="record.uuid"
                >
                </v-radio>
              </v-radio-group>
            </v-row> -->
          </v-col>
          <!-- <v-row v-if="countPage > 1" class="ma-2">
            <v-btn
              v-for="n in countPage"
              :key="n+'page'"
              fab
              height="40px"
              width="40px"
              class="rounded-square grey-button"
              @click="toPage(n)"
            >
              <span>{{n}}</span>
            </v-btn>
          </v-row> -->
        </v-card>
      </v-tab-item>
<!-- Upload New -->
      <v-tab-item>
        <v-card flat class="px-4">
          <v-row
            class="my-1"
          >
            <v-col
              cols="12"
              md="12"
              class="d-flex align-center"
            >
              <label class="fz-14 mb-1 d-block">Recording Name</label>
            </v-col>
            <v-col
              cols="12"
              md="12"
              class="d-flex align-center pt-0"
            >
              <v-text-field
                v-model="recordingName"
                :rules="nameRules"
                :maxlength="60"
                outlined
                dense
                solo
                hide-details
              />
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col
              cols="12"
              md="12"
              class="pt-0"
            >
              <DropOrClick
                :filesUploaded.sync="filesUploaded"
                :fileType="'audio'"
                ref="droporclick"
              />
            </v-col>
          </v-row>
          <audio-spectrum
            v-if="filesUploaded.length"
            :name="recordingName"
            :src="uploadRecording"
          />
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-btn
                :disabled="!recordingName || !recordingName.trim() || !filesUploaded.length"
                height="40px"
                class="rounded-square grey-button"
                @click="save('file')"
              >
                <v-icon>
                  check
                </v-icon>
                <span>Save recording</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
<!-- Record Now -->
      <v-tab-item>
        <v-card flat>
          <v-row class="my-0 px-4">
            <v-col
              cols="12"
              md="12"
              class="pb-0"
            >
              <v-row>
                <v-col
                  cols="8"
                  md="8"
                  class="d-flex align-center"
                >
                  <label class="fz-14 d-block">Recording Name</label>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="d-flex align-center pt-0"
                >
                  <v-text-field
                    v-model="recordingName"
                    :rules="nameRules"
                    :maxlength="60"
                    outlined
                    dense
                    solo
                    hide-details
                  />
                </v-col>
              </v-row>
              <v-row class="d-flex align-center">
                <v-col
                  cols="12"
                  md="4"
                  class="pt-0"
                >
                  <v-btn
                    v-if="!isRecording"
                    text
                    class="text-none d-flex align-center px-2"
                    @click="recordAudio"
                  >
                    <v-icon color="#5D6AC1" class="cursor-pointer">mic</v-icon>
                    <span v-if="hasRecording">Re-Record</span>
                    <span v-else>Start Recording</span>
                  </v-btn>
                  <v-btn
                    v-else
                    text
                    class="text-none d-flex align-center px-2"
                    @click="stop"
                  >
                    <v-icon color="#EB5757">stop</v-icon>
                    <span>Stop Recording</span>
                  </v-btn>
                </v-col>
                <v-col
                  v-if="isRecording"
                  cols="12"
                  md="4"
                  class="pt-0"
                >
                  <audio-spectrum for-recorder :length="50" />
                </v-col>
                <v-col
                  v-if="isRecording"
                  cols="12"
                  md="4"
                  class="d-flex justify-end pt-0"
                >
                  <span class="fz-14">{{ toHourMinSec(recordingDuration) }}</span>
                </v-col>
              </v-row>
              <v-divider v-if="hasRecording" class="my-2" />
              <v-row
                v-if="hasRecording && !isRecording"
                class="d-flex align-center"
              >
                <v-col
                  cols="8"
                  md="8"
                  class="d-flex align-center"
                >
                  <label class="fz-14 d-block">Listen on the Record</label>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="pt-0"
                >
                  <audio-spectrum
                    :name="recordingName"
                    :src="recording"
                  />
                </v-col>
                <v-col
                  cols="6"
                  md="6"
                >
                    <!-- :href="recording"
                    :download="recordingName + '.mp3'" -->
                  <v-btn
                    :disabled="!recordingName || !recordingName.trim()"
                    height="40px"
                    class="rounded-square grey-button"
                    @click="save('record')"
                  >
                    <v-icon>
                      check
                    </v-icon>
                    <span>Save recording</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-col>
  <v-dialog
    v-model="deleteDialog"
    persistent
    :max-width="$vuetify.breakpoint.mdAndUp ? '350' : '260'"
  >
    <v-card>
      <v-card-title class="font-weight-bold">
        Delete
      </v-card-title>

      <v-card-text>
        Are you sure you want to delete recording "{{ deletingRecord.original_filename }}"?
      </v-card-text>

      <v-card-actions class="pb-5 pt-0 justify-md-end">

        <div :style="$vuetify.breakpoint.mdAndUp ? '' : 'width: 50%'"
              class="mx-2">
          <v-btn
            color="secondary"
            outlined
            @click="closeDeleteDialog"
            class="w-100 text-capitalize"
          >
            Cancel
          </v-btn>
        </div>

        <div :style="$vuetify.breakpoint.mdAndUp ? '' : 'width: 50%'"
              class="mx-2">
          <v-btn
            depressed
            color="primary"
            @click="deleteRecord"
            class="w-100 text-capitalize"
          >
            Delete
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-row>
</template>

<script>
import DropOrClick from '../components/DropOrClick'
import AudioSpectrum from '@/components/AudioSpectrum'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'callflow-voice-message',
  components: {
    DropOrClick,
    AudioSpectrum
  },
  props: {
    path: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    tabs: ['Read message', 'Play Existing', 'Upload New', 'Record New'],
    readMessage: '',
    previewMessage: false,
    playMessage: '',
    recordingList: [],
    recordingListPage: 1,
    recordingListMax: 10,
    recordingItem: '',
    playingNow: '',
    playingNowIndex: 0,
    currentTabs: null,
    filesUploaded: [],
    recordingName: '',
    recorder: null,
    stream: null,
    recordedChunk: null,
    recordingBlob: null,
    recording: '',
    paused: true,
    recordingTimer: null,
    recordingDuration: 0,
    nameRules: [
      v => !!v || 'Name is required'
    ],
    deleteDialog: false,
    deletingRecord: {}
  }),
  created () {
    this.loadRecordings()
  },
  watch: {
    recordedChunk () {
      if (!this.recordedChunk) return
      this.recordingBlob = new Blob([this.recordedChunk], {
        type: 'audio/mpeg-3'
      })
      this.recording = window.URL.createObjectURL(this.recordingBlob)
    },
    currentTabs (val) {
      this.setStepValue({
        path: this.path.concat(['activeTab']),
        value: val === 0
      })
      this.stop()
      this.recordingList = this.recordingList.map(item => ({
        ...item,
        selected: false
      }))
    },
    filesUploaded: {
      deep: true,
      handler () {
        if (!this.filesUploaded.length || +this.filesUploaded[0].size < 10485760) return
        this.$snotify.error('Uploaded file is too large')
        setTimeout(() => {
          this.$refs.droporclick.reset()
        }, 100)
      }
    }
  },
  computed: {
    ...mapGetters('callflow', ['getFromPath']),
    isRecording () {
      return this.recorder && this.recorder.state === 'recording'
    },
    hasRecording () { return this.recordedChunk !== null },
    recordingListComputed () {
      return this.recordingList.slice((this.recordingListPage - 1) * this.recordingListMax, this.recordingListMax * this.recordingListPage)
    },
    countPage () {
      return Math.ceil(this.recordingList.length / this.recordingListMax)
    },
    recordMessage () {
      return this.getFromPath(
        this.path.concat('payload', 'text')
      )
    },
    record () {
      return this.getFromPath(
        this.path.concat('payload', 'record')
      )
    },
    uploadRecording () {
      return this.filesUploaded.length ? URL.createObjectURL(this.filesUploaded[0]) : ''
    },
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  methods: {
    ...mapMutations('callflow', ['setStepValue']),
    ...mapActions(['getRecordings', 'deleteRecording']),
    async loadRecordings (uuid) {
      try {
        const { data } = await this.getRecordings({
          orderType: 'DESC',
          orderBy: 'created_on',
          take: 499
        })
        this.recordingList = data.data.map(item => ({
          ...item,
          selected: this.record === item.file_url || uuid === item.uuid
        }))
        if (this.record || uuid) this.currentTabs = 1
        if (uuid) {
          const record = this.recordingList.find(e => e.uuid === uuid)
          if (!record) return
          this.selectRecording(record)
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    setRecordMessage (value) {
      this.setStepValue({
        path: this.path.concat(['payload', 'text']),
        value
      })
      if (!value || !this.$refs.recordMessageSpectrum) return
      this.$refs.recordMessageSpectrum.playTextToSpeech()
      setTimeout(() => {
        this.$refs.recordMessageSpectrum.playTextToSpeech()
      }, 50)
    },
    selectRecording (record) {
      this.setStepValue({
        path: this.path.concat(['payload', 'record']),
        value: record.file_url
      })
      this.recordingList = this.recordingList.map(item => ({
        ...item,
        selected: item.uuid === record.uuid
      }))
    },
    /* toPage (n) {
      this.recordingItem = ''
      this.ended('choosen')
      this.recordingListPage = n
      setTimeout(() => {
        this.recordingItem = this.recordingListComputed[0].uuid
      }, 400)
    }, */
    recordAudio () {
      this.recordedChunk = null
      const device = navigator.mediaDevices.getUserMedia({ audio: true })
      device.then((stream) => {
        this.stream = stream
        this.recorder = new MediaRecorder(stream)
        this.recorder.ondataavailable = this.handleDataAvailable
        this.recorder.start()
        this.recordingDuration = 0
        this.calculateRecDuration()
        setTimeout(() => {
          this.stop()
        }, 60000)
      })
    },
    stop () {
      if (!this.isRecording) return
      this.stream.getTracks().forEach(track => {
        track.enabled = false
        track.stop()
        track = null
      })
      this.recorder.stop()
      this.recorder = null
      clearInterval(this.recordingTimer)
      this.recordingTimer = null
    },
    handleDataAvailable (event) {
      if (event.data.size <= 0) return
      this.recordedChunk = event.data
    },
    calculateRecDuration () {
      if (!this.isRecording) return
      this.recordingTimer = setInterval(() => {
        this.recordingDuration++
      }, 1000)
    },
    save (type) {
      const self = this
      if (type === 'record') {
        this.upload(type, this.recordingDuration)
        return
      }
      const uploadAudio = new Audio()
      uploadAudio.src = URL.createObjectURL(this.filesUploaded[0])
      uploadAudio.addEventListener('loadedmetadata', function () {
        if (uploadAudio.duration === Infinity) {
          uploadAudio.currentTime = 1e101
          uploadAudio.ontimeupdate = function () {
            this.ontimeupdate = () => {
              self.upload(type, uploadAudio.duration)
            }
            uploadAudio.currentTime = 0
          }
        } else {
          self.upload(type, uploadAudio.duration)
        }
      }, false)
    },
    upload (type, duration) {
      let newName = this.recordingName
      let newFile = null
      if (type === 'record') {
        newName = this.recordingName + '.mp3'
        newFile = new File([this.recordingBlob], newName, { type: this.recordingBlob.type })
      } else {
        const name = this.filesUploaded[0].name.split('.')
        newName = this.recordingName + '.' + name[1]
        newFile = new File([this.filesUploaded[0]], newName, { type: this.filesUploaded[0].type })
      }

      const formData = new FormData()
      formData.append('recordName', this.recordingName)
      formData.append('recordDuration', Math.floor(duration))
      formData.append('record', newFile)

      this.$store
        .dispatch('saveRecording', formData)
        .then(async (res) => {
          this.$snotify.notifications = []
          this.$snotify.success('Successful')
          if (this.$refs.droporclick) this.$refs.droporclick.reset()
          this.recordingName = ''
          this.loadRecordings(res.data.recordingUuid)
        }).catch(err => {
          this.$snotify.error(err)
          console.log('err', err)
        })
    },
    openDeleteDialog (record) {
      this.deletingRecord = record
      this.deleteDialog = true
    },
    closeDeleteDialog () {
      this.deleteDialog = false
      this.deletingRecord = {}
    },
    async deleteRecord () {
      this.$snotify.notifications = []
      try {
        if (!this.deletingRecord.uuid) return
        const { data } = await this.deleteRecording(this.deletingRecord.uuid)
        if (!data) return
        this.closeDeleteDialog()
        this.$snotify.success('Recording was deleted successfully')
        await this.loadRecordings()
        if (!this.recordingList.length) return
        this.selectRecording(this.recordingList[0])
      } catch (err) {
        this.$snotify.error(err.message)
        throw new Error(err)
      }
    }
  }
}
</script>

<style>
  .cursor-pointer {
    cursor: pointer;
  }
</style>

<style scoped>
.recording-item:hover {
  background: #EFF1F9;
}
.selectRecording {
  width: 16px;
  height: 16px;
  border: 1px solid #6170C5;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}
.selectRecording.selected::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #6170C5;
}

.mobile-tabs ::v-deep .v-tab:not(.v-tab--active) {
  color: #8B8B8B !important
}
.mobile-tabs ::v-deep .v-tab.v-tab--active {
  color: #6170C5 !important
}
.v-textarea ::v-deep .v-label {
  top: 10px !important;
}
</style>
