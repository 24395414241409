<template>
  <v-timeline-item
    fill-dot
    :color="callflowMenu ? '#5C6AC4' : iconColor"
    :icon="callflowMenu ? 'add' : icon"
  >
    <v-col v-if="hasStep" class="ma-0 pa-0 pl-4 ">
      <v-col
        v-if="!callflowMenu"
        cols="12"
        md="12"
        class="pa-0 my-0 pr-0 d-flex align-center insert-step flex-column mt-4 mt-md-0"
        :class="{ 'max-w-callflow': isXS }"
      >
        <v-btn
          class="px-3 py-0 text-capitalize font-weight-medium"
          :class="[isMobile ? 'mobile-insert-step' : 'gray-button', { 'w-100': isXS }]"
          outlined
          height="35px"
          text
          @click="insertStepMenu"
        >
          <v-icon
            size="24px"
            color="#919EAB"
            class="mr-2"
          >
            add
          </v-icon>

          Insert step
        </v-btn>
        <div class="fz-14 mt-2">
          <slot name="message"/>
          <slot name="insertStep"/>
        </div>
      </v-col>

      <v-col
        v-else
        cols="12"
        md="12"
        class="py-1 pl-0 pr-0"
      >
        <CallflowStepMenu
          :hangUpOption="hangUpOption"
          :stepName="stepName"
          is-visible
          no-timeline
          @insertStepName="insertStepComponent"
        />
      </v-col>
    </v-col>
    <v-col
      cols="12"
      md="12"
      class="pt-0 pb-0 pl-4 pr-0"
    >
      <v-col v-if="branchMessage" class="branch-step d-flex align-center justify-center">
        <div :id="lineId" class="branch-step-name fz-14 line-h-20">{{ branchMessage }}</div>
      </v-col>
      <v-card
        max-width="668px"
        width="100%"
        class="pa-0 my-4"
        :class="{ 'mobile-step': isXS }"
      >
        <v-card-title
          :class="isMobile ? 'fz-14 font-weight-medium' : 'fz-20 line-h-28 font-weight-regular'"
          class="pb-0 d-flex justify-space-between"
        >
          {{ stepName }}
          <v-tooltip top content-class="v-tooltip--top">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                text
                icon
                v-bind="attrs"
                v-on="on"
                @click="$emit('deleteStepIndex')"
              >
                <v-icon color="#919EAB">delete</v-icon>
              </v-btn>
            </template>
            <span>Remove step</span>
          </v-tooltip>
        </v-card-title>

        <slot/>
      </v-card>
    </v-col>
  </v-timeline-item>
</template>

<script>
import CallflowStepMenu from './CallflowStepMenu'
import { mapGetters } from 'vuex'

export default {
  name: 'CallflowStep',
  components: { CallflowStepMenu },
  props: {
    stepName: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    iconColor: {
      type: String,
      default: '#919EAB'
    },
    hangUpOption: {
      type: Boolean,
      default: true
    },
    message: {
      type: String,
      default: ''
    },
    path: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    callflowMenu: false
  }),
  computed: {
    ...mapGetters('callflow', ['steps', 'getFromPath', 'hasError']),
    isXS () {
      return this.$vuetify.breakpoint.xs
    },
    hasStep () {
      const step = this.getFromPath(this.path)
      return !step.parent
    },
    branchMessage () {
      const rawMessage = this.getFromPath(this.path.concat('message'))
      const self = this.getFromPath(this.path)
      const parent = this.steps.find(e => e.id === self.parent)
      if (!rawMessage || !parent) return ''
      let action
      switch (rawMessage.type) {
        case 'menu':
          if (rawMessage.branch !== null) {
            return `The caller presses '${rawMessage.branch}'`
          }
          break
        case 'schedule':
          if (!rawMessage.branch) return 'The call is received in any other time'
          if (!parent.actions || !parent.actions.branches) return ''
          action = parent.actions.branches.findIndex(e => e.id === self.id)
          if (action < 0) return ''
          return `The call is received during the Schedule Branch '${this.getLetter(action)}'.`
        case 'geoRouting':
          if (!rawMessage.branch || rawMessage.branch === 'other') return 'The call is received from any other Location'
          if (!parent.actions) return ''
          action = parent.actions[rawMessage.branch].findIndex(e => e.id === self.id)
          if (action < 0) return ''
          return `The call is received during the Location ${rawMessage.branch === 'georoutingAreaCode' ? 'Area' : 'Zip'} '${action + 1}'.`
      }
      return ''
    },
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    lineId () {
      const defaultVal = `end_${this.path[0]}`
      const rawMessage = this.getFromPath(this.path.concat('message'))
      const self = this.getFromPath(this.path)
      const parentIndex = this.steps.findIndex(e => e.id === self.parent)
      if (!rawMessage || parentIndex < 0) return defaultVal
      if (!rawMessage.branch || rawMessage.branch === 'other') return `end_${parentIndex}_other`
      let action
      const parent = this.steps.find(e => e.id === self.parent)
      switch (rawMessage.type) {
        case 'menu':
          action = parent.actions.branches.findIndex(e => e.id === self.id)
          if (action < 0) return defaultVal
          return `end_${parentIndex}_menu_${action}`
        case 'schedule':
          action = parent.actions.branches.findIndex(e => e.id === self.id)
          if (action < 0) return defaultVal
          return `end_${parentIndex}_branches_${action}`
        case 'geoRouting':
          if (!rawMessage.branch || rawMessage.branch === 'other') return 'The call is received from any other Location'
          action = parent.actions[rawMessage.branch].findIndex(e => e.id === self.id)
          if (action < 0) return defaultVal
          return `end_${parentIndex}_${rawMessage.branch}_${action}`
      }
      return defaultVal
    }
  },
  methods: {
    insertStepMenu () {
      this.callflowMenu = true
    },
    insertStepComponent (component) {
      this.callflowMenu = false
      this.$emit('insertStepComponent', component)
    },
    getLetter (index) {
      return String.fromCharCode('A'.charCodeAt(0) + index)
    }
  }
}
</script>

<style scoped>
.branch-step {
  width: 100%;
}
.branch-step-name {
  position: relative;
  padding-inline: 10px;
}
.branch-step-name:after,
.branch-step-name:before {
  content: '';
  border: 1px solid #DFE3E8;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 1px;
  border-right: 0 !important;
}
.branch-step-name:before {
  left: 0;
  transform: translate(-100%, -50%);
}
.branch-step-name:after{
  right: 0;
  transform: translate(100%, -50%);
}
.mobile-insert-step {
  background: #fff !important;
  color: gray !important;
  font-weight: 500;
  text-transform: none;
}

.max-w-callflow {
  max-width: 274px;
  width: 100%;
}

.mobile-step {
  max-width: 274px !important;
}
</style>
