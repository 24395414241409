<template>
  <callflow-step
    :hang-up-option="hangUpOption"
    :path="path"
    icon="voicemail"
    step-name="Voicemail"
    @insertStepComponent="insertStep"
    @deleteStepIndex="$emit('deleteStepIndex')"
  >
    <CallflowVoiceMessage :path="path" />
    <v-card class="d-flex align-center flex-wrap py-0 ml-1" style="box-shadow:none">
      <v-col cols="12" class="py-0">Mailboxes</v-col>
      <v-col cols="6">
        <v-select
          :value="selectedMailbox"
          :items="mailboxes"
          item-text="label"
          item-value="value"
          item-disabled="disabled"
          menu-props="offsetY"
          label="Select mailbox"
          solo
          outlined
          hide-details
          class="py-0"
          style="height: 38px; min-width: 115px"
          @change="changeSelectedMailbox($event)"
        >
          <template v-slot:append>
            <v-icon size="15px">unfold_more</v-icon>
          </template>
          <template v-slot:no-data><span></span></template>
          <template v-slot:append-item>
            <v-btn text class="w-100 justify-start create-mailbox-button" @click="showCreateDialog = true">
              <v-icon size="15px">add</v-icon>
              Create Mailbox
            </v-btn>
          </template>
        </v-select>
      </v-col>
    </v-card>
    <div v-if="showCreateDialog" class="create-mailbox-wrapper">
      <v-dialog
        v-model="showCreateDialog"
        persistent
        :max-width="700"
      >
        <div class="create-mailbox">
          <div class="d-flex align-center justify-space-between">
            <p class="font-semi-bold mt-3 ml-5 fz-24 pa-0 mb-0">Create Mailbox</p>
            <v-btn icon class="create-mailbox-close" @click="showCreateDialog = false">
              <v-icon size="25px">close</v-icon>
            </v-btn>
          </div>
          <create-mailbox-component
            is-dialog
            ref="createMailbox"
            class="create-mailbox-component"
            @isDisable="e => isDisable = e"
            @close="close"
          />
          <v-btn :disabled="isDisable" class="w-max primary mx-auto text-none" text @click="create">Create</v-btn>
        </div>
      </v-dialog>
    </div>
    <!-- <template v-slot:message>The caller’s area code is different than any location above </template> -->
    <vue-snotify/>
  </callflow-step>
</template>

<script>
import CreateMailboxComponent from '@/components/CreateMailboxComponent'
import CallflowVoiceMessage from './CallflowVoiceMessage'
import CallflowStep from './CallflowStep'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'CallflowVoicemail',
  components: {
    CallflowStep,
    CallflowVoiceMessage,
    CreateMailboxComponent
  },
  props: {
    hangUpOption: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: 0
    },
    path: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    mailboxes: [],
    showCreateDialog: false,
    isDisable: true
  }),
  created () {
    this.loadMailboxes()
  },
  computed: {
    ...mapGetters('callflow', ['getFromPath']),
    selectedMailbox () {
      return this.getFromPath(
        this.path.concat('payload', 'selectedMailbox')
      )
    }
  },
  methods: {
    ...mapActions(['getMailboxes']),
    ...mapMutations('callflow', ['setStepValue']),
    insertStep (component) {
      this.$emit('insertStepComponent', { name: component.name, index: this.index })
    },
    async loadMailboxes () {
      try {
        const { data } = await this.getMailboxes({ take: 499 })
        if (!data) return
        this.mailboxes = data.mailboxList.map(item => ({
          label: item.name,
          value: item.uuid
        }))
      } catch (err) {
        throw new Error(err)
      }
    },
    changeSelectedMailbox (value) {
      this.setStepValue({
        path: this.path.concat('payload', 'selectedMailbox'),
        value
      })
    },
    create () {
      if (!this.$refs.createMailbox) return
      this.$refs.createMailbox.create()
    },
    close () {
      this.showCreateDialog = false
      this.loadMailboxes()
    }
  }
}
</script>

<style scoped lang="scss">
.create-mailbox {
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 20px;
  &-button {
    height: auto !important;
    padding: 16px 14px !important;
  }
  &-component {
    padding-top: 0 !important;
  }
}
</style>
