<template>
  <div class="pt-5">
    <h1 class="dark-text font-weight-semi-bold fz-28 px-7 px-md-0 d-none d-md-block">Mailboxes</h1>
    <h1 class="dark-text font-weight-semi-bold fz-25 px-7 px-md-0 d-md-none mb-5">Mailboxes</h1>

    <div v-if="$store.state.auth.currentUser.role === 'account_admin'" class="d-flex align-center px-6 px-md-0 py-2 cursor-pointer">
      <v-btn
        text
        class="text-none d-flex align-center px-2 font-weight-regular"
        style="color: #637381;"
        height="36px"
        @click.stop.prevent="$router.push({ name: 'CreateMailbox', params: {isChange : false} })"
      >
        <v-icon color="#919EAB">add</v-icon>
        Create Mailbox
      </v-btn>
      <v-col cols="2" class="pa-0">
        <v-select
          v-model="filter"
          :items="filters"
          item-text="label"
          item-value="value"
          menu-props="offsetY"
          class="ml-5 mailbox-filter"
          solo
          outlined
          hide-details
          style="height: 38px"
        >
          <template v-slot:append>
            <v-icon size="15px">unfold_more</v-icon>
          </template>
        </v-select>
      </v-col>
    </div>
    <v-card :loading="loading" class="pa-0 mb-5 overflow-hidden" style="min-height:4px">
      <v-list v-if="!loading" class="py-0">
        <template v-for="(mailbox, index) in mailboxes">
          <v-divider
            v-if="index"
            :key="index + 'divider'"
            class="hl-724"
          ></v-divider>
<!--          desktop-->
          <v-list-item :key="index" class="px-4 d-none d-md-flex align-center" :to="{ name: 'Mailbox', params: { boxName: mailbox.name, uuid: mailbox.uuid } }">
            <v-list-item-content class="py-2">
              <v-list-item-title class="fz-14 font-weight-semi-bold">
                {{ mailbox.name }}
              </v-list-item-title>

              <v-list-item-subtitle
                class="py-1"
                style="color: #637381;"
              >
                <span>{{ mailbox.users ? mailbox.users.length : 0 }} assigned users</span>
                <span class="ml-5">{{ mailbox.isShared ? 'Public' : 'Private' }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action v-if="$store.state.auth.currentUser.role === 'account_admin'" class="flex-row align-center ma-0">
              <v-tooltip v-if="!mailbox.isShared" top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    @click.stop.prevent="assignUser(assignUserPopUp = !assignUserPopUp, mailbox)"
                    v-on="on"
                  >
                    <v-icon color="#919EAB">person_add</v-icon>
                  </v-btn>
                </template>
                <span>Add user</span>
              </v-tooltip>

              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :to="{ name: 'EditMailbox', params: { uuid: mailbox.uuid } }"
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#919EAB">edit</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>

              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop.prevent="openDeleteDialog(mailbox.uuid)"
                  >
                    <v-icon color="#919EAB">delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>

<!--          mobile-->
          <v-list-item :key="index + 'listitem'" class="pl-7 pr-3 d-md-none">
            <v-list-item-content class="py-2">
              <v-list-item-title class="fz-14 font-weight-semi-bold" style="max-width: fit-content"
                @click.stop.prevent="$router.push({ name: 'Mailbox', params: { boxName: mailbox.name, uuid: mailbox.uuid } })">
                {{ mailbox.name }}
              </v-list-item-title>

              <v-list-item-subtitle
                class="py-1"
                style="color: #637381;"
              >
                <span>{{ mailbox.users ? mailbox.users.length : 0 }} assigned users</span>
                <span class="ml-5">{{ mailbox.isShared ? 'Public' : 'Private' }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action class="flex-row align-center v-list-item__action--hover ma-0">
              <v-tooltip v-if="!mailbox.isShared" top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    @click.stop.prevent="assignUser(assignUserPopUp = !assignUserPopUp, mailbox)"
                    v-on="on"
                  >
                    <v-icon color="#919EAB">person_add</v-icon>
                  </v-btn>
                </template>
                <span>Add user</span>
              </v-tooltip>

              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop.prevent="$router.push({ name: 'EditMailbox', params: { uuid: mailbox.uuid } })"
                  >
                    <v-icon color="#919EAB">edit</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>

              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop.prevent="openDeleteDialog(mailbox.uuid)"
                  >
                    <v-icon color="#919EAB">delete</v-icon>
                  </v-btn>
                </template>
                <span>Remove</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>

        </template>
      </v-list>
      <v-list v-if="!mailboxes.length && !loading" class="d-flex justify-center pa-3">Mailbox list is empty</v-list>
      <div v-if="total > take && !loading" class="d-flex justify-space-between">
        <div class="ma-5 br-3 grey-button d-flex">
          <template v-for="index in countPage">
            <v-divider :key="index + 'v-divider--page'" v-if="index" vertical class="pa-0"/>
            <v-btn
              :class="{'grey-button--selected': index === page}"
              text
              tile
              min-width="56"
              class=""
              :key="index"
              @click="pagination(index)"
            >{{ index }}
            </v-btn>
          </template>
        </div>

        <div class="ma-5 br-3 grey-button d-flex">
          <v-btn
            text
            tile
            class="px-0"
            min-width="36"
            width="36px"
            @click="previousPage(page)"
          >
            <v-icon small>
              navigate_before
            </v-icon>
          </v-btn>
          <v-divider vertical/>
          <v-btn
            text
            tile
            min-width="36"
            class="light-text px-0"
            width="36px"
            @click="nextPage(page)"
          >
            <v-icon small>
              navigate_next
            </v-icon>
          </v-btn>
        </div>
      </div>
    </v-card>
    <vue-snotify/>
    <v-dialog
      persistent
      v-model="deleteDialog"
      :max-width="$vuetify.breakpoint.mdAndUp ? '350' : '260'"
    >
      <v-card>
        <v-card-title class="font-weight-bold">
          Delete mailbox
        </v-card-title>

        <v-card-text>
          Are you sure you want to delete mailbox?
        </v-card-text>

        <v-card-actions class="pb-5 pt-0 justify-md-end">

          <div :style="$vuetify.breakpoint.mdAndUp ? '' : 'width: 50%'"
              class="mx-2">
            <v-btn
              color="secondary"
              outlined
              class="w-100 text-capitalize"
              @click="closeDeleteDialog"
            >
              Cancel
            </v-btn>
          </div>

          <div :style="$vuetify.breakpoint.mdAndUp ? '' : 'width: 50%'"
              class="mx-2">
            <v-btn
              depressed
              color="primary"
              @click="deleteMailbox"
              class="w-100 text-capitalize"
            >
              Delete
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Mailboxes',
  data: () => ({
    mailboxes: [],
    total: 0,
    deleteUuid: '',
    deleteDialog: false,
    renderKey: 0,
    loading: false,
    filter: {},
    page: 1,
    take: 10,
    filters: [
      { label: 'All', value: {} },
      { label: 'Public', value: { isShared: true } },
      { label: 'Private', value: { isShared: false } }
    ]
  }),
  computed: {
    ...mapState('components', [
      'rightPopUpMyContacts',
      'selectedMailbox'
    ]),
    assignUserPopUp: {
      get () {
        return this.rightPopUpMyContacts
      },
      set (videoValue) {
        return videoValue
      }
    },
    countPage () {
      return Math.ceil(this.total / this.take)
    }
  },
  created () {
    this.init()
  },
  watch: {
    selectedMailbox (val) {
      this.mailboxes.forEach(item => {
        if (item.uuid === val.uuid) {
          // console.log(item.uuid, val.uuid)
          item.users = val.users
        }
      })
    },
    filter: {
      deep: true,
      handler () {
        this.init()
      }
    },
    page () {
      this.init()
    }
  },
  methods: {
    ...mapActions(['getMailboxes', 'getMailbox']),
    async init () {
      try {
        this.loading = true
        const { data } = await this.getMailboxes({
          ...this.filter,
          skip: (this.page - 1) * this.take,
          take: this.take,
          orderBy: 'created_on',
          orderType: 'DESC'
        })
        if (!data) return
        this.total = data.total
        if (!this.total) return this.$router.push({ name: 'CreateMailbox', params: { isChange: false } })
        if (this.$store.state.auth.currentUser.role !== 'account_admin') {
          this.mailboxes = data.mailboxList
          return
        }
        const mailboxes = []
        const calls = []

        data.mailboxList.forEach((mailbox, index) => {
          calls.push(this.getMailbox(mailbox.uuid))
        })

        Promise.all(calls).then(callsData => {
          callsData.forEach((mail, index) => {
            mailboxes.push({
              ...data.mailboxList[index],
              ...mail.data.mailbox
            })
          })
        })
        this.mailboxes = mailboxes
      } catch (err) {
        throw new Error(err)
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 1500)
      }
    },
    openDeleteDialog (uuid) {
      this.deleteDialog = true
      this.deleteUuid = uuid
    },
    closeDeleteDialog () {
      this.deleteDialog = false
      this.deleteUuid = ''
    },
    deleteMailbox () {
      this.$store
        .dispatch('deleteMailbox', this.deleteUuid)
        .then((res) => {
          this.$snotify.notifications = []
          this.$snotify.success('Successful')
          this.init()
        }).catch(err => {
          this.$snotify.notifications = []
          this.$snotify.error(err.message)
          throw new Error(err)
        }).finally(() => {
          this.closeDeleteDialog()
        })
    },
    assignUser (payload, mailbox) {
      this.$store.dispatch('components/RightPopUpMyContacts', payload)
      this.$store.dispatch('components/setSelectedMailbox', mailbox)
    },
    pagination (index) {
      this.page = index
    },
    previousPage (page) {
      if (page > 1) {
        this.page = page - 1
      }
    },
    nextPage (page) {
      if (page < this.countPage) {
        this.page = page + 1
      }
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.mailbox-filter ::v-deep .v-input__slot {
  min-width: 120px;
}
</style>
