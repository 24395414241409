<template>
  <callflow-step
    :hang-up-option="hangUpOption"
    icon="label"
    step-name="Tag"
    :path="path"
    @insertStepComponent="insertStep"
    @deleteStepIndex="$emit('deleteStepIndex')"
  >
    <v-row class="ma-0">
      <v-col
        cols="12"
        md="12"
        class="py-0 px-4"
        :class="!createTag ? 'pb-3' : ''"
      >
        <div v-if="tags.length" class="tag-wrapper">
          <v-chip
            v-for="(tag, index) in tags"
            class="tags pr-0"
            :key="index + 'tag'"
          >
            {{ tag.tagName }}

            <v-tooltip top content-class="v-tooltip--top">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="ma-0 ml-1"
                  height="32px"
                  width="32px"
                  @click="removeTag(index)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="#919EAB" size="20px">close</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-chip>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="12"
        class="pa-4"
        v-if="createTag"
      >
        <label class="fz-14 mb-1 d-block">Add tag</label>
        <v-text-field
          v-model="tag"
          :maxlength="24"
          solo
          outlined
          type="text"
          dense
          hide-details
        ></v-text-field>
      </v-col>

      <v-col
        cols="12"
        md="12"
        class="pt-0"
        :class="createTag ? 'px-4' : ''"
      >
        <v-btn
          :text="!createTag"
          class="text-none d-flex align-center px-2"
          :class="[createTag ? 'violet-button px-3' : '', isMobile ? 'font-weight-medium dark-grey-text': 'font-weight-regular']"
          height="36px"
          @click="createTag ? addTag() : showInput()"
        >
          <v-icon
            :size="isMobile ? '16px' : '24px'"
            color="#919EAB"
            v-if="!createTag"
          >
            add
          </v-icon>
          {{ createTag ? 'Create tag' : 'Add tag' }}
        </v-btn>
      </v-col>
    </v-row>
    <!-- <template v-slot:message>The caller’s area code is different than any location above</template> -->
  </callflow-step>
</template>

<script>
import CallflowStep from './CallflowStep'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'CallflowTag',
  components: { CallflowStep },
  props: {
    hangUpOption: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: 0
    },
    path: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    tag: '',
    createTag: false
  }),
  computed: {
    ...mapGetters('callflow', ['getFromPath']),
    tags () {
      return this.getFromPath(
        this.path.concat('payload', 'tags')
      )
    },
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  methods: {
    ...mapMutations('callflow', ['pushToStep', 'removeFromStep']),
    showInput () {
      this.createTag = true
    },
    addTag () {
      if (this.tag.trim() !== '') {
        this.pushToStep({
          path: this.path.concat(['payload', 'tags']),
          value: { tagName: this.tag }
        })
        this.tag = ''
        this.createTag = false
      }
    },
    removeTag (index) {
      this.removeFromStep({
        path: this.path.concat(['payload', 'tags', index]),
        value: this.tags
      })
    },
    insertStep (component) {
      this.$emit('insertStepComponent', { name: component.name, index: this.index })
    }
  }
}
</script>

<style scoped lang="scss">
  .tag-wrapper {
    border: 1px solid #C4CDD5;
    width: max-content;
    max-width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
  }
</style>
