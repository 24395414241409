<template>
    <v-row v-if="isVisible" @click.stop.prevent class="pl-4 my-0 steps">
      <v-col
        cols="12"
        md="12"
      >
        <v-card
          max-width="668px"
          width="100%"
          class="pa-0"
          :class="{'br-3': !isMobile, 'max-w-callflow': isXS}"
        >
          <v-card-title
            :class="isMobile ? 'fz-14 px-3': 'line-h-28 fz-20 font-weight-regular'"
            class="pb-0"
          >What would you like to happen first?</v-card-title>

          <v-col
            cols="12"
            md="12"
            class="py-0 px-0"
          >
            <v-list
              class="hover-background pt-1"
            >
              <template v-for="(step, stepIndex) in steps">
                  <v-divider
                    v-if="stepIndex"
                    :key="stepIndex"
                  ></v-divider>

                  <v-list-item
                    :key="stepIndex + 'step'"
                    class="align-start px-4"
                  >
                    <v-list-item-avatar
                      width="50px"
                      height="50px"
                      style="border-radius: 100%;"
                      :color="step.color"
                      class="my-3 align-start"
                      :class="{ 'list-item-avatar-mobile': isMobile, 'list-item-avatar-no-timeline': noTimeline }"
                    >
                      <v-icon
                        :color="step.iconColor"
                        :size="isMobile ? '15px' : '30px'"
                      >
                        {{ step.icon }}
                      </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content
                      class="py-4"
                    >
                      <v-list-item-title
                        :class="isMobile ? 'font-weight-bold' : 'font-weight-semi-bold'"
                        class="mb-1 d-flex align-center justify-space-between"
                      >
                        <span>{{ step.title }}</span>
                        <v-avatar
                          v-if="isMobile"
                          class="insert-step-mobile d-flex align-center justify-center"
                          :color="step.color"
                          @click="insertStepName(step.componentName)"
                        >
                          <v-icon color="#fff" size="16px">add</v-icon>
                        </v-avatar>
                      </v-list-item-title>

                      <v-list-item-subtitle
                        class="text-wrap"
                      >
                        {{ step.subtitle }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action
                      v-if="!isMobile"
                      class="d-flex flex-row align-center"
                    >
                      <v-btn
                        class="br-3 text-none px-3"
                        height="36px"
                        :class="step.buttonClass"
                        @click="insertStepName(step.componentName)"
                      >
                        <v-icon
                          class="mr-1"
                        >
                          add
                        </v-icon>
                        Insert
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
              </template>
            </v-list>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CallflowStepMenu',
  props: {
    hangUpOption: {
      type: Boolean,
      default: true
    },
    stepName: {
      type: String,
      default: ''
    },
    isVisible: {
      type: Boolean,
      default: false
    },
    noTimeline: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    stepSettings: [
      {
        title: 'Greeting',
        subtitle: 'Play a message to the caller. Frequently used to notify the caller about call recording.',
        icon: 'message',
        iconColor: '#919EAB',
        color: '#DFE3E8',
        buttonClass: 'grey-button',
        componentName: 'CallflowGreeting'
      },
      {
        title: 'Dial',
        subtitle: 'This is where the phone will ring when customers dial your tracking number.',
        icon: 'phone',
        iconColor: '#919EAB',
        color: '#DFE3E8',
        buttonClass: 'grey-button',
        componentName: 'CallflowDial'
      },
      {
        title: 'Simulcall',
        subtitle: 'We\'ll dial all numbers in the Simulcall at the same time. The first person to answer will be connected to the caller.',
        icon: 'ring_volume',
        iconColor: '#919EAB',
        color: '#DFE3E8',
        buttonClass: 'grey-button',
        componentName: 'CallflowSimulcall'
      },
      {
        title: 'Menu',
        subtitle: 'Prompt the caller to select from a menu of options using the keypad on their phone.',
        icon: 'dialpad',
        iconColor: '#919EAB',
        color: '#DFE3E8',
        buttonClass: 'grey-button',
        componentName: 'CallflowMenu'
      },
      {
        title: 'Schedule',
        subtitle: 'Create a schedule that determines how your calls should be routed.',
        icon: 'history',
        iconColor: '#919EAB',
        color: '#DFE3E8',
        buttonClass: 'grey-button',
        componentName: 'CallflowSchedule'
      },
      {
        title: 'Geo-routing',
        subtitle: 'Route callers to different destinations automatically by area code, or manually by ZIP code.',
        icon: 'map',
        iconColor: '#919EAB',
        color: '#DFE3E8',
        buttonClass: 'grey-button',
        componentName: 'CallflowGeoRouting'
      },
      /* {
        title: 'Tag',
        subtitle: 'Add one or more tags to the call.',
        icon: 'label',
        iconColor: '#919EAB',
        color: '#DFE3E8',
        buttonClass: 'grey-button',
        componentName: 'CallflowTag'
      }, */
      {
        title: 'Voicemail',
        subtitle: 'Route callers to the voicemail.',
        icon: 'voicemail',
        iconColor: '#919EAB',
        color: '#DFE3E8',
        buttonClass: 'grey-button',
        componentName: 'CallflowVoicemail'
      },
      {
        title: 'Hang Up',
        subtitle: 'The call will be disconnected.',
        icon: 'call_end',
        iconColor: '#ED6347',
        color: '#FDAC9A',
        buttonClass: 'red-button',
        componentName: 'CallflowHangUp',
        isLast: true
      }
    ]
  }),
  computed: {
    ...mapGetters('callflow', ['hasVoicemail']),
    steps () {
      console.log(this.hasVoicemail, 'this.hasVoicemail')
      // return this.stepSettings.filter(step => !(step.isLast && step.title !== this.stepName && !this.hangUpOption))
      return this.stepSettings.filter(step => !((step.isLast && !this.hangUpOption) ||
        (step.title === 'Voicemail' && this.hasVoicemail)) && step.title !== this.stepName)
    },
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    isXS () {
      return this.$vuetify.breakpoint.xs
    }
  },
  methods: {
    insertStepName (componentName) {
      this.$emit('insertStepName', { name: componentName })
    }
  }
}
</script>

<style lang="scss" scoped>
.steps {
  cursor: default;
}

.max-width {
  width: max-content;
}
.list-item-avatar-mobile {
  width: 30px !important;
  height: 30px !important;
  min-width: 30px !important;
  position: absolute;
  left: -50px;
  top: 0;
  z-index: 100;
}
.insert-step-mobile {
  width: 22px !important;
  height: 22px !important;
  min-width: 22px !important;
}

.max-w-callflow {
  max-width: 274px !important;
}
</style>
