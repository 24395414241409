<template>
  <v-menu
    v-model="menu"
    ref="menu"
    :close-on-content-click="false"
    :return-value.sync="menu"
    transition="scale-transition"
    offset-y
    attach
    min-width="auto"
    class="menu-mobile"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        solo
        outlined
        hide-details
        v-bind="attrs"
        v-on="on"
        class="grey-button time"
      >
        <span>{{ hours }}:{{ minutes }}</span>
      </v-btn>
      <v-btn
        solo
        outlined
        hide-details
        class="grey-button period"
        @click="period = (period === 'AM' ? 'PM' : 'AM')"
      >
        <span>{{ period }}</span>
      </v-btn>
    </template>
    <div class="d-flex dropdown">
      <div class="dropdown-list">
        <v-list>
          <v-list-item v-for="(hour, index) in hourOptions" :key="index + 'hour'" @click="hours = hour">{{ hour }}</v-list-item>
        </v-list>
      </div>
      <div class="dropdown-list">
        <v-list>
          <v-list-item v-for="(minute, index) in minuteOptions" :key="index + 'minute'" @click="minutes = minute">{{ minute }}</v-list-item>
        </v-list>
      </div>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: 'TimeDropdown',
  props: {
    value: {
      type: String,
      required: false
    },
    endTime: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    menu: false,
    hours: '',
    hourOptions: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
    minutes: '',
    minuteOptions: ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'],
    period: '',
    periodOptions: ['AM', 'PM']
  }),
  computed: {
    dropdownValue () {
      let hours = +this.hours
      let minutes = +this.minutes
      if (this.period === 'PM' && hours < 12) hours = hours + 12
      if (this.period === 'AM' && hours === 12) hours = hours - 12
      if (hours < 10) hours = '0' + hours
      if (minutes < 10) minutes = '0' + minutes
      return `${hours}:${minutes}`
    }
  },
  watch: {
    dropdownValue (val) {
      this.$emit('change', val)
    },
    period () {
      this.$emit('change', this.dropdownValue)
    },
    value () {
      this.setTime()
    }
  },
  created () {
    this.reset()
  },
  methods: {
    reset () {
      if (this.endTime) {
        this.hours = '11'
        this.minutes = '55'
        this.period = 'PM'
      } else {
        this.hours = '01'
        this.minutes = '00'
        this.period = 'AM'
      }
    },
    setTime () {
      let time = this.value.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [this.value]

      if (time.length < 2) return
      time = time.slice(1)
      time[5] = +time[0] < 12 ? 'AM' : 'PM'
      time[0] = +time[0] % 12 || 12
      this.hours = time[0]
      this.minutes = time[2]
      this.period = time[5]
    }
  }
}
</script>

<style scoped lang="scss">
.v-menu__content {
  @media (max-width: 959px) {
    right: 0 !important;
    left: 0 !important;
    overflow-x: auto !important;
    max-width: 100% !important;
    margin-top: 12px !important;
  }
}

.grey-button {
  min-width: 30px !important;
  border: none;
  color: #212B36 !important;
}

.time {
  padding: 0 0 0 5px !important;
  border-radius: 3px 0 0 3px !important;
}

.period {
  padding: 0 5px 0 0 !important;
  border-radius: 0 3px 3px 0 !important;
}

.dropdown {
  .dropdown-list {
    max-height: 186px;
    overflow-y: auto;
    .v-list-item {
      min-width: 66px;
      min-height: 40px;
      &:hover {
        color: #ffffff;
        background-color: #5C6AC4;
      }
    }

    &::-webkit-scrollbar {
      width: 16px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #E7E7E7;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #919EAB;
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      background-color: #AAAAAA;
      border-radius: 9999px;
    }
  }
}
</style>
