<template>
  <callflow-step
    :hang-up-option="hangUpOption"
    :path="path"
    icon="message"
    step-name="Greeting"
    @insertStepComponent="insertStep"
    @deleteStepIndex="$emit('deleteStepIndex')"
  >
    <CallflowVoiceMessage :path="path" />
    <vue-snotify/>
  </callflow-step>
</template>

<script>
import CallflowStep from './CallflowStep'
import CallflowVoiceMessage from './CallflowVoiceMessage'

export default {
  name: 'CallflowGreeting',
  components: {
    CallflowStep,
    CallflowVoiceMessage
  },
  props: {
    hangUpOption: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    path: {
      type: Array,
      required: true
    }
  },
  methods: {
    insertStep (component) {
      this.$emit('insertStepComponent', { name: component.name, index: this.index })
    }
  }
}
</script>

<style scoped lang="scss">
  ::v-deep .v-menu__content {
    border: 1px solid #c4cdd5!important;
    contain: initial;
    overflow: visible;
    &:before {
      position: absolute;
      content: "";
      top: 0;
      right: 13px;
      transform: translateY(-100%);
      width: 8px;
      height: 8px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid #fff;
      z-index: 9 !important;
    }

    &:after {
      position: absolute;
      content: "";
      top: 0;
      right: 11px;
      transform: translateY(-100%);
      width: 10px;
      height: 10px;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 9px solid #bac2c9;
    }
  }
  ::v-deep .hide-search input {
    z-index: -1 !important;
  }
</style>
