<template>
  <callflow-step
    :hang-up-option="hangUpOption"
    :path="path"
    icon="restore"
    step-name="Schedule"
    @insertStepComponent="insertStep"
    @deleteStepIndex="$emit('deleteStepIndex')"
  >
    <v-row class="my-0">
      <v-col
        cols="12"
        md="12"
        class="px-3"
      >
        <p v-if="hasError" class="mx-4 red--text">The time interval is overlapped</p>
        <v-row
          v-for="(branch, branchIndex) in schedule"
          :key="branchIndex"
        >
          <v-col
            cols="12"
            md="12"
            class="px-3 pb-0 actions-row"
          >
            <v-alert
              :class="[
                branchActions.length <= branchIndex || !branchActions[branchIndex] || branchActions[branchIndex].type < 0 ? 'error-alert' : 'info-alert'
              ]"
              class="mb-0 fz-14"
              tile
              close-icon="close"
            >
              <v-row>
                <v-col
                  cols="8"
                  md="9"
                  class="py-0 align-center d-flex"
                >
                  <div>
                    Schedule Branch {{ getLetter(branchIndex) }}:
                    <span class="ml-1" style="color: #637381;">If a call is received during the following times</span>
                  </div>
                </v-col>

                <v-col
                  cols="3"
                  md="2"
                  class="py-0 d-flex justify-end"
                >
                  <v-btn
                    v-if="branchActions.length <= branchIndex || !branchActions[branchIndex] || branchActions[branchIndex].type < 0"
                    :disabled="hasError"
                    class="circle"
                    text
                    icon
                    @click="setAction(['branches', branchIndex])"
                  >
                    <v-icon size="20px">
                      warning
                    </v-icon>
                  </v-btn>
                  <v-select
                    v-else
                    :value="branchActions[branchIndex].type"
                    :items="stepList"
                    item-text="label"
                    item-value="value"
                    menu-props="offsetY"
                    solo
                    outlined
                    hide-details
                    style="height:38px;min-width:115px;margin-top:2px"
                    @change="changeActionType($event, ['branches', branchIndex])"
                  >
                    <template v-slot:append>
                      <v-icon size="15px">unfold_more</v-icon>
                    </template>
                  </v-select>
                </v-col>

                <v-col
                  cols="1"
                  md="1"
                  class="py-0"
                >
                  <v-btn
                    :disabled="hasError"
                    text
                    icon
                    @click="removeScheduleBranch(branchIndex)"
                  >
                    <v-icon>
                      close
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-alert>
            <callflow-toggle-action
              v-if="branchActions.length > branchIndex && branchActions[branchIndex] && branchActions[branchIndex].type >= 0"
              :trigger="isActionVisible(['branches', branchIndex])"
              :id="`start_${path[0]}_branches_${branchIndex}`"
              @toggle="toggleActionVisibility(['branches', branchIndex])" />
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="px-7 pt-0"
          >
            <v-row
              class="align-top mt-0 mb-6 mb-md-0 mb-lg-0"
              v-for="(item, branchItemIndex) in branch"
              :key="branchItemIndex"
            >
              <v-col
                cols="11"
                md="3"
                class="pb-0"
              >
                <v-select
                  :value="item.dayOfWeek"
                  :items="days"
                  :disabled="hasError"
                  item-text="name"
                  item-value="value"
                  menu-props="offsetY"
                  solo
                  outlined
                  hide-details
                  class="pa-0"
                  @change="changeSelect($event, branchIndex, branchItemIndex, 'dayOfWeek')"
                >
                  <template v-slot:append>
                    <v-icon size="15px">unfold_more</v-icon>
                  </template>
                </v-select>
              </v-col>
              <v-col
                v-if="$vuetify.breakpoint.smAndDown"
                cols="1"
                class="text-right pr-lg-2 pr-3 pl-0"
              >
                <v-btn
                  :disabled="hasError"
                  text
                  icon
                  @click="removeScheduleBranchItem(branchIndex, branchItemIndex)"
                >
                  <v-icon>
                    close
                  </v-icon>
                </v-btn>
              </v-col>

              <v-col
                cols="11"
                md="3"
                class="pb-0"
              >
                <v-select
                  :value="item.rangeType"
                  :items="formatRange"
                  :disabled="hasError"
                  item-text="name"
                  item-value="value"
                  menu-props="offsetY"
                  solo
                  outlined
                  hide-details
                  class="pa-0"
                  @change="changeSelect($event, branchIndex, branchItemIndex, 'rangeType')"
                >
                  <template v-slot:append>
                    <v-icon size="15px">unfold_more</v-icon>
                  </template>
                </v-select>
              </v-col>
              <v-col
                v-if="$vuetify.breakpoint.mdAndUp || item.rangeType !== 0"
                cols="11"
                md="5"
                lg="4"
              >
                <div v-if="item.rangeType !== 0" class="select-container">
                  <!-- <v-select
                    :value="item.rangeType === 1 ? item.startTime : item.fromTime"
                    :items="time"
                    :disabled="hasError && (branchItemIndex !== errors.scheduleOverlap.branchItemIndex || errors.scheduleOverlap.name === 'endTime')"
                    item-text="name"
                    item-value="value"
                    class="box-shadow-0 mb-0 pa-0"
                    :class="{'mr-4': item.rangeType === 1}"
                    hide-details
                    menu-props="offsetY"
                    append-icon=""
                    dense
                    solo
                    @change="changeSelect($event, branchIndex, branchItemIndex, item.rangeType === 1 ? 'startTime' : 'fromTime')"
                  ></v-select>
                  <v-select
                    v-if="item.rangeType === 1"
                    :value="item.endTime"
                    :items="time"
                    :disabled="hasError && (branchItemIndex !== errors.scheduleOverlap.branchItemIndex || errors.scheduleOverlap.name !== 'endTime')"
                    item-text="name"
                    item-value="value"
                    class="box-shadow-0 pa-0 ml-n2"
                    menu-props="offsetY"
                    append-icon=""
                    hide-details
                    dense
                    solo
                    @change="changeSelect($event, branchIndex, branchItemIndex, 'endTime')"
                  ></v-select> -->
                  <div>
                    <time-dropdown :value="item.startTime" @change="changeSelect($event, branchIndex, branchItemIndex, 'startTime')" />
                  </div>
                  <span>-</span>
                  <div>
                    <time-dropdown :value="item.endTime" end-time @change="changeSelect($event, branchIndex, branchItemIndex, 'endTime')" />
                  </div>
                  <v-icon>arrow_drop_down</v-icon>
                </div>

              </v-col>

              <v-col
                v-if="$vuetify.breakpoint.mdAndUp"
                cols="12"
                md="1"
                lg="2"
                class="text-right pr-lg-2 pr-3 pl-0"
              >
                <v-btn
                  :disabled="hasError"
                  text
                  icon
                  @click="removeScheduleBranchItem(branchIndex, branchItemIndex)"
                >
                  <v-icon>
                    close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pt-0 pb-0"
          >
            <v-btn
              :disabled="hasError || disableAdd(branch)"
              text
              class="text-none d-flex align-center px-2 ml-4"
              height="36px"
              @click="addSchedule(branchIndex)"
            >
              <v-icon size="24px" color="#919EAB">add</v-icon>
              Add time
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="12"
        class="px-7 d-flex d-md-block align-center justify-center"
      >
        <v-btn
          :disabled="hasError"
          :style="{ width: isMobile ?'calc(100% - 29px)' : 'max-content' }"
          :height="isMobile ? '41px' : ''"
          class="violet-button px-2"
          @click="newBranch"
        >
          Add Schedule Branch
        </v-btn>
      </v-col>

      <v-col
        cols="12"
        md="12"
        class="actions-row"
      >
        <v-alert
          class="mb-0 fz-14"
          style="padding-block:4px !important"
          :class="[!otherAction || otherAction.type < 0 ? 'error-alert' : 'info-alert']"
          tile
        >
          <v-col cols="9" md="9">Any other time</v-col>
          <v-col
            cols="3"
            md="3"
            class="pa-0 ma-0 d-flex align-center justify-end"
          >
            <v-btn
              v-if="!otherAction || otherAction.type < 0"
              :disabled="hasError"
              class="circle"
              text
              icon
              @click="setAction(['other'])"
            >
              <v-icon size="20px">
                warning
              </v-icon>
            </v-btn>
            <v-select
              v-else
              :value="otherAction.type"
              :items="stepList"
              item-text="label"
              item-value="value"
              item-disabled="disabled"
              menu-props="offsetY"
              solo
              outlined
              hide-details
              style="height:38px;min-width:115px;margin-top:2px"
              @change="changeActionType($event, ['other'])"
            >
              <template v-slot:append>
                <v-icon size="15px">unfold_more</v-icon>
              </template>
            </v-select>
          </v-col>
        </v-alert>
        <callflow-toggle-action
          v-if="otherAction && otherAction.type >= 0"
          :trigger="isActionVisible(['other'])"
          :id="`start_${path[0]}_other`"
          @toggle="toggleActionVisibility(['other'])" />
      </v-col>
    </v-row>
  </callflow-step>
</template>

<script>
import CallflowStep from './CallflowStep'
import CallflowToggleAction from './CallflowToggleAction'
import TimeDropdown from './TimeDropdown'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'CallflowSchedule',
  components: { CallflowStep, CallflowToggleAction, TimeDropdown },
  props: {
    hangUpOption: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: 0
    },
    path: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    time: [
      { name: '12:00 PM', value: '00' },
      { name: '01:00 AM', value: '01' },
      { name: '02:00 AM', value: '02' },
      { name: '03:00 AM', value: '03' },
      { name: '04:00 AM', value: '04' },
      { name: '05:00 AM', value: '05' },
      { name: '06:00 AM', value: '06' },
      { name: '07:00 AM', value: '07' },
      { name: '08:00 AM', value: '08' },
      { name: '09:00 AM', value: '09' },
      { name: '10:00 AM', value: '10' },
      { name: '11:00 AM', value: '11' },
      { name: '12:00 AM', value: '12' },
      { name: '01:00 PM', value: '13' },
      { name: '02:00 PM', value: '14' },
      { name: '03:00 PM', value: '15' },
      { name: '04:00 PM', value: '16' },
      { name: '05:00 PM', value: '17' },
      { name: '06:00 PM', value: '18' },
      { name: '07:00 PM', value: '19' },
      { name: '08:00 PM', value: '20' },
      { name: '09:00 PM', value: '21' },
      { name: '10:00 PM', value: '22' },
      { name: '11:00 PM', value: '23' }
    ],
    fromModel: { name: '00:00 AM', value: '00' },
    toModel: { name: '00:00 AM', value: '00' },
    days: [
      { name: 'Weekdays', value: 0 },
      { name: 'Monday', value: 1 },
      { name: 'Tuesday', value: 2 },
      { name: 'Wednesday', value: 3 },
      { name: 'Thursday', value: 4 },
      { name: 'Friday', value: 5 },
      { name: 'Saturday', value: 6 },
      { name: 'Sunday', value: 7 },
      { name: 'Weekend', value: 8 }
    ],
    formatRange: [
      { name: 'All day', value: 0 },
      { name: 'Between', value: 1 }
      // { name: 'From', value: 2 }
    ],
    stepListData: [
      { value: 0, label: 'Greeting' },
      { value: 1, label: 'Dial' },
      { value: 2, label: 'Simulcall' },
      // { value: 6, label: 'Tag' },
      { value: 8, label: 'HangUp' }
    ],
    error: {}
  }),
  computed: {
    ...mapGetters('callflow', ['getFromPath', 'steps', 'errors', 'hasHangUp']),
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    schedule () {
      return this.getFromPath(
        this.path.concat('payload', 'schedule')
      )
    },
    otherAction () {
      return this.getFromPath(this.path.concat('actions', 'other'))
    },
    branchActions () {
      return this.getFromPath(this.path.concat('actions', 'branches'))
    },
    hasError () { return Object.keys(this.errors.scheduleOverlap).length > 0 },
    stepList () {
      return this.stepListData.map(item => ({
        ...item,
        disabled: item.value === 8 && this.hasHangUp
      }))
    }
  },
  methods: {
    ...mapMutations('callflow', ['setStepValue', 'pushToStep', 'removeFromStep', 'setError']),
    ...mapActions('callflow', ['addStep', 'calculateLines']),
    removeScheduleBranch (branchIndex) {
      console.log('removeScheduleBranch', branchIndex)
      const action = this.branchActions[branchIndex]
      const child = this.steps.findIndex(e => e.id === action.id)
      const childrenIndex = this.getFromPath(this.path).children.findIndex(e => e === action.id)
      this.removeFromStep({
        path: this.path.concat('actions', 'branches', branchIndex)
      })
      this.removeFromStep({
        path: this.path.concat('payload', 'schedule', branchIndex)
      })
      if (child < 0) return
      this.removeFromStep({
        path: this.path.concat('children', childrenIndex)
      })
      this.removeFromStep({
        path: [child]
      })
      setTimeout(() => {
        this.calculateLines()
      }, 500)
    },
    removeScheduleBranchItem (branchIndex, branchItemIndex) {
      this.removeFromStep({
        path: this.path.concat('payload', 'schedule', branchIndex, branchItemIndex)
      })
      setTimeout(() => {
        this.calculateLines()
      }, 500)
    },
    addSchedule (branchIndex) {
      const value = {
        isDefault: false,
        dayOfWeek: 0,
        rangeType: 0
      }

      this.pushToStep({
        path: this.path.concat(['payload', 'schedule', branchIndex]),
        value
      })
      setTimeout(() => {
        this.calculateLines()
      }, 500)
    },
    newBranch () {
      const value = [{
        isDefault: false,
        dayOfWeek: 0,
        rangeType: 0
      }]

      this.pushToStep({
        path: this.path.concat(['payload', 'schedule']),
        value
      })
      this.pushToStep({
        path: this.path.concat(['actions', 'branches']),
        value: { name: '', type: -1 }
      })
      setTimeout(() => {
        this.calculateLines()
      }, 500)
    },
    changeSelect (value, branchIndex, branchItemIndex, name) {
      this.setStepValue({
        path: this.path.concat('payload', 'schedule', branchIndex, branchItemIndex, name),
        value
      })
      const schedule = this.getFromPath(this.path.concat('payload', 'schedule', branchIndex))
      const branch = schedule[branchItemIndex]
      let error = false
      let startTime
      let endTime
      let prevBranch
      let nextBranch
      switch (name) {
        case 'startTime':
          endTime = branch.endTime ? branch.endTime : '23:55'
          if (value >= endTime) {
            error = true
            this.error = { branchItemIndex, name }
          }

          if (branchItemIndex === 0) break
          prevBranch = schedule[branchItemIndex - 1]
          endTime = prevBranch.endTime ? prevBranch.endTime : '23:55'
          if (value <= endTime) {
            error = true
            this.error = { branchItemIndex, name }
          }
          break
        case 'endTime':
          startTime = branch.startTime ? branch.startTime : branch.fromTime ? branch.fromTime : '01:00'
          if (value <= startTime) {
            error = true
            this.error = { branchItemIndex, name }
          }

          if (branchItemIndex + 1 === schedule.length) break
          nextBranch = schedule[branchItemIndex + 1]
          startTime = nextBranch.startTime ? nextBranch.startTime : nextBranch.fromTime ? nextBranch.fromTime : '01:00'
          if (value >= startTime) {
            error = true
            this.error = { branchItemIndex, name }
          }
          break
        /* case 'fromTime':
          endTime = parseInt(branch.endTime ? branch.endTime : 23)
          if (parseInt(value) >= endTime) {
            error = true
            this.error = { branchItemIndex, name }
          }

          if (branchItemIndex === 0) break
          prevBranch = schedule[branchItemIndex - 1]
          endTime = parseInt(prevBranch.endTime ? prevBranch.endTime : 23)
          if (parseInt(value) <= endTime) {
            error = true
            this.error = { branchItemIndex, name }
          }
          break */
      }
      this.setError({ path: 'scheduleOverlap', error: this.error })
      // console.log(this.error, 'list')
      if (this.hasError && this.error.branchItemIndex === branchItemIndex && this.error.name === name) {
        this.error = {}
      }
      setTimeout(() => {
        this.calculateLines()
      }, 500)
    },
    insertStep (component) {
      this.$emit('insertStepComponent', { name: component.name, index: this.index })
    },
    getLetter (index) {
      return String.fromCharCode('A'.charCodeAt(0) + index)
    },
    setAction (path) {
      const id = Date.now()
      const name = path.length > 1 ? 'Branch' : ''
      this.setStepValue({
        path: this.path.concat('actions', path),
        value: { name, type: 0, id }
      })

      this._addStep(id, -1, 0, false, name)
      this.pushToStep({ path: this.path.concat('children'), value: id })
    },
    _addStep (id, index, stepListIndex, isReplacement, branch) {
      console.log(id, index, stepListIndex, isReplacement, branch)
      const parent = this.getFromPath(this.path)

      const name = 'Callflow' + this.stepList[stepListIndex].label

      const path = [this.path[this.path.length - 1] + (isReplacement ? 0 : 1) + (isReplacement ? index + 1 : parent.children.length)]

      if (index < 0) index = parent.children.length
      const message = { type: 'schedule', branch }

      this.addStep({ path, name, parent: parent.id, id, message, isReplacement })
      setTimeout(() => {
        this.calculateLines()
      }, 500)
    },
    changeActionType (value, path) {
      const id = Date.now()
      const name = path.length > 1 ? 'Branch' : ''
      const parent = this.getFromPath(this.path)
      const action = this.getFromPath(this.path.concat('actions', path))
      const childIndex = parent.children.findIndex(e => e === action.id)
      const stepListIndex = this.stepList.findIndex(e => e.value === value)
      this.setStepValue({
        path: this.path.concat('actions', path),
        value: { name, type: value, id }
      })
      this._addStep(id, childIndex, stepListIndex, true, name)
      this.setStepValue({
        path: this.path.concat('children', childIndex),
        value: id
      })
    },
    isActionVisible (path) {
      const action = this.getFromPath(this.path.concat('actions', path))
      const children = this.steps.find(e => e.id === action.id)
      return !children || children.isVisible
    },
    toggleActionVisibility (path) {
      const parent = this.getFromPath(this.path)
      const action = this.getFromPath(this.path.concat('actions', path))
      const childIndex = parent.children.findIndex(e => e === action.id)
      const childPath = [this.path[this.path.length - 1] + childIndex + 1]
      const child = this.getFromPath(childPath)
      this.setStepValue({
        path: childPath.concat('isVisible'),
        value: !child.isVisible
      })
      setTimeout(() => {
        this.calculateLines()
      }, 500)
    },
    disableAdd (branch) {
      const dayArr = branch.map(item => item.dayOfWeek)
      const isDuplicateDay = dayArr.some((item, idx) => dayArr.indexOf(item) !== idx)
      const rangeArr = branch.map(item => item.rangeType)
      const isDuplicateRange = isDuplicateDay && rangeArr.some((item, idx) => rangeArr.indexOf(item) !== idx)
      return isDuplicateDay || isDuplicateRange
    }
  }
}
</script>

<style scoped lang="scss">
  .select-container {
    display: flex;
    min-height: fit-content;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #C4CDD5;
    border-radius: 3px;
    max-width: 189px;
  }
  .box-shadow-0 ::v-deep {
    .v-input__slot {
      box-shadow: none !important;
      padding: 0 !important;
      display: block !important;
    }
    .v-input__control {
      min-height: 0 !important;
    }
  }
  .v-select-list ::v-deep .v-list-item {
    padding: 0 6px !important;
  }
  ::v-deep .v-select.v-input--dense .v-select__selection--comma {
    margin-left: 12px !important;
    margin-right: 12px !important;
    white-space: normal !important;
  }
</style>
